import React from 'react';
import {observer} from 'mobx-react';
import {Button, Col, Row} from 'antd';

import {ComponentWithStore} from 'models/RootStore';
import {Story} from 'models/story/Story';
import {Homepage as HomepageModel} from 'models/homepage/Homepage';

import DefaultLayout from 'layouts/DefaultLayout';

import './About.scss';
import {Helmet} from "react-helmet";

// This renders either itself or Logged out Homepage.
class About extends ComponentWithStore<any, any> {
  constructor(props) {
    super(props);
  }

  public render(): React.ReactElement {
    return (
      <DefaultLayout>
        <Helmet>
          <title>Rascal - About</title>
        </Helmet>
        <div className='rk-about-layout'>
          {this.renderAboutBannerSection()}
          {this.renderInteractionSection()}
          {this.renderSafetySection()}
          {this.renderLibrarySection()}
          {this.renderControlsSection()}
          {/*{this.renderStreamingSection()}*/}
        </div>
      </DefaultLayout>
    );
  }

  private renderAboutBannerSection() {
    return (
      <div className='aboutpage--banner'>
        <div className={'video-banner'}>
          <div className={'video-banner-content'}>
            <video poster={''} muted={true} playsInline={true} loop={true} autoPlay={true} preload={"auto"}>
              <source src="https://assets.rascalkids.app/static/kid-ipad.mp4" type="video/mp4" />
            </video>
          </div>
          <div className={'faded-background'}></div>
        </div>
        <Row>
          <Col xs={24} sm={{ span:22, offset:1 }} md={{ span:17, offset: 4 }} lg={{ span:18, offset: 3 }} xl={{ span:16, offset: 4 }} xxl={{ span:12, offset: 6 }} className='aboutpage--section-text'>
            <p>Made for kids</p>
            <h1>Play. Watch. Read.</h1>
            <div>
              <h3>Providing an alternative for screen time. Interactive stories for kids to play,
                while reading and engaging with a story.
              </h3>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  private renderInteractionSection() {
    return (
      <div className={'about--section large-top-margin'}>
        <Row>
          <Col xs={24} sm={{ span:22, offset:1 }} md={{ span:16, offset: 4 }} xl={{ span:12, offset: 6 }} className='aboutpage--section-text'>
            <p>Interaction</p>
            <h2>Single touch gestures.</h2>
            <div>
              <h3>Experience seamless storytelling with single-touch gestures. Designed with
                simplicity, a single touch on the screen is all it takes to progress through the narrative.
              </h3>
            </div>
          </Col>
        </Row>
        <div className={'about--tile-images'}>
          <div className={'about--tile-wrapper about-images-with-text block-together'}>
            <div className={'block1'}>
              <div className={'section-image-left'}>
                <img alt={'Phone with Rascal Story'} src={'/images/01_tablet-lbt-landscape.png'}/>
              </div>
            </div>
            <div className={'block2'}>
              <div className={'section-image-right'}>
                <img alt={'Phone with Rascal Story'} src={'/images/01_tablet-L&S-landscape.gif'}/>
              </div>
            </div>
            <div className={'block3'}>
              <p className={'about--text-block-title'}>Button Design</p>
              <p>Rascal's unique button design, visibly shifting in each scene,
                promoting patience and engagement, ensuring a purposeful storytelling experience.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private renderSafetySection() {
    return (
      <div className='about--section'>
        <Row>
          <Col xs={24} sm={{ span:22, offset:1 }} md={{ span:16, offset: 4 }} xl={{ span:12, offset: 6 }} className='aboutpage--section-text'>
            <p>Safety</p>
            <h2>Protected play.</h2>
            <div>
              <h3>Discover a secure environment for your child. Entirely ad-free and no
                in-app purchases, immersing them in captivating stories without any distractions.
              </h3>
            </div>
          </Col>
        </Row>
        <div className={'about--tile-images'}>
          <div className={'about--tile-wrapper about-image-with-text'}>
            <div className={'block1'}>
              <div className={'section-image-single'}>
                <img className={'overflow-image'} alt={'Phone with Rascal Story'} src={'/images/02_tablet_rascalhp.png'}/>
              </div>
            </div>
            <div className={'block2'}>
              <div className={'text-block text-block-right'}>
                <div>
                  <p className={'about--text-block-title'}>Ad-Free Experience</p>
                  <p>
                    Our commitment to ad-free extends beyond removing visual interruptions;
                    we believe in digital spaces where you feel safe and your child's imagination
                    can flourish without the influence of external marketing.
                  </p>
                </div>
                <div>
                  <p className={'about--text-block-title'}>Peace of Mind</p>
                  <p>
                    Our readable content is crafted to provide short stories for a guilt-free 10 minutes.
                    Rascal's commitment to quality content, offers a balanced and controlled exposure to screen-time.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private renderLibrarySection() {
    return (
        <div className='about--section'>
          <Row>
            <Col xs={24} sm={{ span:22, offset:1 }} md={{ span:16, offset: 4 }} xl={{ span:12, offset: 6 }} className='aboutpage--section-text'>
              <p>Library</p>
              <h2>Diverse Stories</h2>
              <div>
                <h3>In addition to our original stories, we proudly collaborate with creative teams from around the world,
                  bringing a diverse array of captivating content to Rascal.
                </h3>
              </div>
            </Col>
          </Row>
          <div className={'about--tile-images'}>
            <div className={'about--tile-wrapper about-image-with-text left'}>
              <div className={'block1 image-right'}>
                <div className={'section-image-single'}>
                  <img className={'overflow-image'} alt={'Phone with Rascal Story'} src={'/images/03_tabletdiversestories.png'}/>
                </div>
              </div>
              <div className={'block2'}>
                <div className={'text-block text-block-left'}>
                  <div>
                  <p className={'about--text-block-title'}>Multiple Genres</p>
                  <p>Our continued curated collection spans multiple genres, providing a
                    diverse range of stories that cater to different interests and preferences.</p>
                  </div>
                  <div>
                    <p className={'about--text-block-title'}>Language</p>
                    <p>Language options, currently featuring Te Reo Māori and English, as
                    we expand to introduce even more linguistic experiences. Promoting cultural appreciation and a love
                      for diverse narratives.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }

  private renderControlsSection() {
    return (
      <div className='about--section'>
        <Row>
          <Col xs={24} sm={{ span:22, offset:1 }} md={{ span:12, offset: 2 }} className='aboutpage--section-text left'>
            <p>Controls</p>
            <h2>Music & narration.</h2>
            <div>
              <h3>Ease the journey of reading by letting Rascal take the reins. Enjoy narrated stories with
                music! A supplementary option for device handovers, enhancing the reading experience without replacing
                traditional story time.
              </h3>
            </div>
          </Col>
        </Row>
        <div className={'about--section-dual-image'}>
          <div className={'about--image'}>
            <img alt={'Phone with Rascal Story'} src={'/images/04_tablet-lbd-musicnarration.png'}/>
          </div>
          <div className={'about--image'}>
            <img alt={'Phone with Rascal Story'} src={'/images/04_tablet-shadow-musicnarration.png'}/>
          </div>
        </div>
      </div>
    );
  }

  private renderStreamingSection() {
    return (
      <div className='about--section'>
        <Row>
          <Col xs={24} sm={{ span:22, offset:1 }} md={{ span:12, offset: 6 }} className='aboutpage--section-text'>
            <p>Streaming</p>
            <h2>Performance by design.</h2>
            <div className={'aboutpage--text-block'}>
              <h3>Our stories are downloadable, but like streaming video services, our stories don’t take long to start
              </h3>
            </div>
          </Col>
        </Row>
        <div>
          <div className={'aboutpage--image'}>
            <img alt={'Phone with Rascal Story'} src={'/images/interactive-tile-image.png'}/>
          </div>
        </div>
      </div>
    );
  }

}



export default About;
