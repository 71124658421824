import {AbstractStore} from 'models/AbstractStore';
import {RootStore} from 'models/RootStore';
import {User} from 'models/user/User';
import {IUserApiSearchParams, UserApi} from 'models/user/UserApi';
import {
    IUserApiData,
    IUserApiDataDeleteRequest,
    IUserApiDataEmailUpdateRequest,
    IUserApiDataNameUpdateRequest,
    IUserApiDataPasswordUpdateRequest,
    IUserApiDataStatsResponse,
    IUserApiDataUpdateRequest
} from 'models/user/IUserApiData';
import {IUserSearchApiData, UserSearchData} from 'models/user/IUserSearchApiData';
import {ApiResponseData} from 'models/ApiResponseData';

export class UserProvider extends AbstractStore {
    public constructor(rootStore: RootStore) {
        super(rootStore, 'User');

        this.apiDataToModel = this.apiDataToModel.bind(this);
    }

    public resetPasswordTokenIsValid(email: string | null, token: string | null): Promise<boolean> {
        return UserApi.resetPasswordTokenIsValid(email, token);
    }

    public resetPassword(body): Promise<boolean> {
        return UserApi.resetPassword(body);
    }

    public forgotPassword(body): Promise<boolean> {
        return UserApi.forgotPassword(body);
    }

    public user(userId: number = this.SessionProvider.userId(), force: boolean = false): Promise<IUserApiData> {
        if (force) return this.getFromApiAndUpdateStore(userId);

        return this.UserMemoryStore.read(userId)
            .then((user: IUserApiData | null) => {
                return user
                    ? this.apiDataToModel(user)
                    : this.getFromApiAndUpdateStore(userId);
            });
    }

    public getUserSearch(searchParams?: IUserApiSearchParams): Promise<UserSearchData> {
        return UserApi.getUserSearch(searchParams)
            .then((data: IUserSearchApiData) => ({
                page: data.page,
                page_size: data.page_size,
                total: data.total,
                users: this.apiDataArrayToModelArray(data.users)
            }));
    }

    public update(userId: number, body: IUserApiDataUpdateRequest): Promise<ApiResponseData> {
        return UserApi.update(userId, body)
            .then((response: ApiResponseData) => this.saveNameChange(response, userId, body));
    }

    public changeNames(userId: number, body: IUserApiDataNameUpdateRequest): Promise<ApiResponseData> {
        return UserApi.changeNames(userId, body)
            .then((response: ApiResponseData) => this.saveNameChange(response, userId, body));
    }

    private saveNameChange(response: ApiResponseData, userId: number, body: IUserApiDataUpdateRequest) {
        if (!response.success) return response;

        return this.UserMemoryStore.read(userId)
            .then((user: IUserApiData | null) => {
                if (!user) return response;

                user.first_name = body.first_name || user.first_name;
                user.last_name = body.last_name || user.last_name;
                return this.UserMemoryStore.store(user)
                    .then(() => response);
            });
    }

    public changeEmail(userId: number, body: IUserApiDataEmailUpdateRequest): Promise<ApiResponseData> {
        return UserApi.changeEmail(userId, body)
            .then((response: ApiResponseData) => {
                if (!response.success) return response;

                return this.UserMemoryStore.read(userId)
                    .then((user: IUserApiData | null) => {
                        if (!user) return response;

                        user.email = body.email || user.email;
                        return this.UserMemoryStore.store(user)
                            .then(() => response);
                    })
            });
    }

    public changePassword(userId: number, body: IUserApiDataPasswordUpdateRequest): Promise<ApiResponseData> {
        return UserApi.changePassword(userId, body);
    }

    public deleteSessions(userId: number = this.SessionProvider.userId()): Promise<ApiResponseData> {
        return UserApi.deleteSessions(userId);
    }

    public deleteUser(userId: number, body: IUserApiDataDeleteRequest): Promise<ApiResponseData> {
        return UserApi.deleteUser(userId, body);
    }

    public getPlatform(userId: number = this.SessionProvider.userId()) {
        return UserApi.getPlatform(userId);
    }

    public getStats(userId: number): Promise<IUserApiDataStatsResponse> {
        return UserApi.getStatsFor(userId);
    }

    private getFromApiAndUpdateStore(userId: number): Promise<User> {
        return UserApi.user(userId)
            .then((user: IUserApiData) => this.UserMemoryStore.store(user)
                .then(() => this.apiDataToModel(user)));
    }

    private apiDataToModel(user: IUserApiData): User {
        return new User(this).withData(user);
    }

    public apiDataArrayToModelArray(apiUsers?: IUserApiData[]): User[] {
        return apiUsers ? apiUsers.map(this.apiDataToModel) : [];
    }
}
