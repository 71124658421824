import React, {Component} from "react";
import {Button, FormInstance, Input, Modal} from "antd";
import {RootStore} from "models/RootStore";
import logger from "loglevel";
import Api from "models/Api";
import {UserApi} from "models/user/UserApi";
import Turnstile from "react-turnstile";
import {observable} from "mobx";

import './NewsletterModal.scss';

const turnstileSiteKey: string = process.env.TURNSTILE_SITE_KEY;

interface INewsletterModalProps {
  onClose: () => void;
}

interface INewsletterModalState {
  open: boolean;
  submitting: boolean;
  finished: boolean;
  firstName: string;
  lastName: string;
  email: string;
  captchaResponse: string;
}

class NewsletterModal extends Component<INewsletterModalProps, INewsletterModalState> {

  private store: RootStore;

  constructor(props: INewsletterModalProps) {
    super(props);
    this.store = RootStore.getStore();
    this.state = {
      open: true,
      submitting: false,
      finished: false,
      firstName: '',
      lastName: '',
      email: '',
      captchaResponse: null
    }
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.expiredCallback = this.expiredCallback.bind(this);
  }

  public errorCallback(): void {
  }

  public verifyCallback(response: string): void {
    this.setState({
      captchaResponse: response
    });
  }

  public expiredCallback(): void {
    this.setState({
      captchaResponse: null
    });
  }

  public onSubmit() {
    this.setState({
      open: false
    });
    this.props.onClose();
  }

  public onCancel() {
    this.setState({
      open: false
    });
    this.props.onClose();
  }

  private isSubmitDisabled(): boolean {
    const {submitting, captchaResponse} = this.state;
    return captchaResponse == null || submitting;
  }

  public render() {
    const {open, submitting} = this.state;
    return (
      <Modal centered open={open} confirmLoading={submitting} destroyOnClose={true} onOk={this.onSubmit} onCancel={this.onCancel} footer={[]}>
        {this.renderModalContent()}
      </Modal>
    );
  }

  registerToNewsletter() {
    const {firstName, lastName, email, captchaResponse} = this.state;
    this.setState({
      submitting: true
    });
    UserApi.registerNewsletter({
      first_name: firstName,
      last_name: lastName,
      email: email,
      'cf-turnstile-response': captchaResponse
    }).then((response) => {
      if (response.success) {
        this.setState({
          finished: true
        });
      }
      this.setState({
        submitting: false
      })
    }).catch((error) => {
      if (error.status) {
        console.log(error);
      } else {
        console.log('An unexpected error has occurred', error);
      }

      this.setState({
        submitting: false
      });
    })
  }

  renderSubmit() {
    const {firstName, lastName, email, finished} = this.state;
    if (finished) {
      return <p>Thank you - we have registered you to our newsletter.</p>
    } else {
      return (
        <React.Fragment>
          <Input name={'first_name'} placeholder={'First name'} onChange={(event) => this.setState({firstName: event.target.value})} value={firstName} />
          <Input name={'last_name'} placeholder={'Last name'} onChange={(event) =>  this.setState({lastName: event.target.value})} value={lastName} />
          <Input name={'email'} type={'email'} placeholder={'Email'} onChange={(event) =>  this.setState({email: event.target.value})} value={email}/>
          <Turnstile
                theme={'light'}
                sitekey={turnstileSiteKey}
                onError={this.errorCallback}
                onVerify={this.verifyCallback}
                onExpire={this.expiredCallback}
                retry={'auto'}
                retryInterval={8000}
            />
          <Button onClick={() => this.registerToNewsletter()} disabled={this.isSubmitDisabled()}>Submit</Button>
        </React.Fragment>
      );
    }
  }

  renderModalContent() {
    const {finished} = this.state;
    return (
      <React.Fragment>
        <div className={"newsletter--modal-content"}>
          <p><b>SUBSCRIBE</b></p>
          {!finished && <p>Sign up to our newsletter to receive updates on new releases, updates and moregit  from Rascal.</p>}
        </div>
        <div className={"newsletter--modal-input"}>
          {this.renderSubmit()}
        </div>
      </React.Fragment>
    )
  }
}

export default NewsletterModal;
