import React, {Component} from "react";
import {Select} from "antd";
import {ComponentWithStore} from "models/RootStore";
import {ISettingsListener} from "models/settings/ISettingsListener";
import { Settings } from "models/settings/Settings";

interface ILanguageSelectionProps {
  options?: string[];
  color?: 'white' | 'black';
  defaultLanguage: string;
  onChange: (value: string) => void;
}

interface ILanguageSelectionState {
  currentLanguage: string;
}

class LanguageSelection extends ComponentWithStore<ILanguageSelectionProps, ILanguageSelectionState> implements ISettingsListener {

  constructor(props: ILanguageSelectionProps) {
    super(props);
    this.state = {
      currentLanguage: props.defaultLanguage
    };
  }

  componentDidMount() {
    this.store.SettingsProvider.registerUpdateListener(this);
  }

  componentWillUnmount() {
    this.store.SettingsProvider.unregisterUpdateListener(this);
  }

  onSettingsUpdate(settings: Settings): void {
      this.setState({ currentLanguage: settings.language });
  }

  render() {
    const {onChange, color} = this.props;
    const {currentLanguage} = this.state;
    let options = this.props.options;
    if (!options || options.length === 0) {
      options = ['English', 'Te Reo'];
    }

    return (
      <Select className={`story-view--language-selection ${color ? color : 'black'}`}
          value={currentLanguage}
          defaultValue={currentLanguage}
          onChange={onChange}
          bordered={false}>
          {options.map(o => {
              return (
                <Select.Option key={o} value={o}>
                    {o}
                </Select.Option>
              );
          })}
      </Select>
    );
  }
}

export default LanguageSelection
