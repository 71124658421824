import {RootStore} from 'models/RootStore';
import {IndexedDBWrapper} from 'models/IndexedDBWrapper';
import {WebglAppConfig} from 'models/webgl/WebglAppApi';
import {IWebglBlobs} from 'models/webgl/WebglProvider';

export class WebglIDB extends IndexedDBWrapper {
    private static readonly KEY_NAMES = {
        data: 'data',
        framework: 'framework',
        loader: 'loader',
        wasm: 'wasm',
        version: 'version',
    };

    // This has to be kept in sync with app/models/webgl_app_config.rb
    public static readonly FILE_DATA_TYPES = {
        data: {extension: 'data', content_type: 'gzip', required_in_dev: true},
        framework: {extension: 'framework.js', content_type: 'application/javascript', required_in_dev: true},
        loader: {extension: 'loader.js', content_type: 'application/javascript', required_in_dev: true},
        wasm: {extension: 'wasm', content_type: 'application/wasm', required_in_dev: true},
        data_compressed: {extension: 'data.gz', content_type: 'gzip', required_in_dev: false},
        framework_compressed: {extension: 'framework.js.gz', content_type: 'gzip', required_in_dev: false},
        wasm_compressed: {extension: 'wasm.gz', content_type: 'gzip', required_in_dev: false}
    };

    public constructor(rootStore: RootStore) {
        super(rootStore, 'WebGL');
    }

    public open(): Promise<void> {
        return super.open();
    }

    public storeData(blob: Blob): Promise<void> {
        return super.doStore<Blob>(WebglIDB.KEY_NAMES.data, blob);
    }

    private readData(): Promise<Blob> {
        return super.doRead<Blob>(WebglIDB.KEY_NAMES.data);
    }

    public storeFramework(blob: Blob): Promise<void> {
        return super.doStore<Blob>(WebglIDB.KEY_NAMES.framework, blob);
    }

    private readFramework(): Promise<Blob> {
        return super.doRead<Blob>(WebglIDB.KEY_NAMES.framework);
    }

    public storeLoader(blob: Blob): Promise<void> {
        return super.doStore<Blob>(WebglIDB.KEY_NAMES.loader, blob);
    }

    private readLoader(): Promise<Blob> {
        return super.doRead<Blob>(WebglIDB.KEY_NAMES.loader);
    }

    public storeWasm(blob: Blob): Promise<void> {
        return super.doStore<Blob>(WebglIDB.KEY_NAMES.wasm, blob);
    }

    private readWasm(): Promise<Blob> {
        return super.doRead<Blob>(WebglIDB.KEY_NAMES.wasm);
    }

    public storeVersion(value: string): Promise<void> {
        return super.doStore<string>(WebglIDB.KEY_NAMES.version, value);
    }

    public readVersion(): Promise<string> {
        return super.doRead<string>(WebglIDB.KEY_NAMES.version);
    }

    public requiresUpdate(currentVersion: string): Promise<boolean> {
        return this.readVersion().then((version: string) => {
            console.log(`Current version: ${currentVersion}, stored version: ${version}, ${version !== currentVersion}`);
            return version !== currentVersion
        });
    }

    public storeAll(blobs: IWebglBlobs, config: WebglAppConfig): Promise<void> {
        return Promise.all([
            this.WebglIDB.storeData(blobs.dataBlob),
            this.WebglIDB.storeFramework(blobs.frameworkBlob),
            this.WebglIDB.storeLoader(blobs.loaderBlob),
            this.WebglIDB.storeWasm(blobs.wasmBlob)
        ])
            .then(() => this.WebglIDB.storeVersion(config.version))
        // Store version last so that we don't update the version unless all the other data is successfully stored
    }

    public readAll(): Promise<IWebglBlobs> {
        let blobs: IWebglBlobs = {
            dataBlob: null,
            frameworkBlob: null,
            loaderBlob: null,
            wasmBlob: null
        };

        return Promise.all([
            this.readData().then((blob: Blob) => blobs.dataBlob = blob),
            this.readFramework().then((blob: Blob) => blobs.frameworkBlob = blob),
            this.readLoader().then((blob: Blob) => blobs.loaderBlob = blob),
            this.readWasm().then((blob: Blob) => blobs.wasmBlob = blob)
        ]).then(() => blobs);
    }
}
