import React from 'react';
import {Button, Col, Row} from 'antd';

import {ComponentWithStore, withStore} from 'models/RootStore';

import DefaultLayout from 'layouts/DefaultLayout';

import './policies/PrivacyPolicy.scss';
import {Helmet} from "react-helmet";

// This renders either itself or Logged out Homepage.
class FAQ extends ComponentWithStore<any, any> {

  public render(): React.ReactElement {
    return (
      <DefaultLayout>
        <Helmet>
          <title>Rascal - FAQs</title>
        </Helmet>
        {this.renderFAQSection()}
      </DefaultLayout>
    );
  }

  private renderFAQSection() {
    return (
      <div className='policy--pages'>
        <Row>
          <Col xs={24} sm={{ span:22, offset:1 }} md={{ span:17, offset: 4 }} lg={{ span:18, offset: 3 }} xl={{ span:16, offset: 4 }} xxl={{ span:12, offset: 6 }} className='aboutpage--section-text'>
            <h1>FAQ</h1>
            <div>
              <h3>
                We really appreciate that you have questions about our new app!
                Rascal strives to keep things as clear as possible, if we haven't answered your question below, don't hesitate to reach out.
              </h3>
            </div>
            <div>
              <h2 className={"custom-list-title"}>
                1. How much does Rascal cost?
              </h2>
              <p>
                Think of Rascal as your digital storytelling buddy, available for just $7 NZD a month – that's the same as
                treating yourself to two fluffy cappuccinos at your favorite local cafe. For even more savings and
                unlimited fun, opt for our annual subscription plan at just $60 NZD.
              </p>

              <h2 className={"custom-list-title"}>
                2. How do I download Rascal?
              </h2>
              <p>
                To start your Rascal adventure, the first step is to create an account.
              </p>
              <ul className={"no-decoration"}>
                <li>
                  <ol>
                    <li>
                      Simply visit our website at <a href={"https://rascalkids.app/"} target={"_blank"}>rascalkids.app</a> click "Let's Start".
                      Our user-friendly signup process will guide you every step of the way.
                    </li>
                    <li>
                      Once you've successfully created an account, you can start playing Rascal on your device, or you can
                      head over to your mobile app store. Whether you're using
                      <a href={"https://apps.apple.com/us/app/rascal/id1612015226"} target={"_blank"}> iOS</a> or
                      <a href={"https://play.google.com/store/apps/details?id=app.rascalkids.rascal"} target={"_blank"}> Android</a>,
                      you can find us on both stores under 'Rascal'. Alternatively, we'll provide a convenient
                      download link right after you sign up.
                    </li>
                    <li>
                      After downloading the app to your chosen device, you can log in and dive into the world of Rascal,
                      accessing all the fantastic content we have to offer.
                    </li>
                  </ol>
                </li>
              </ul>

              <h2 className={"custom-list-title"}>
                3. How many devices can I use Rascal on?
              </h2>
              <p>
                Rascal can be downloaded onto as many devices as you own, mobile, tablet and the web on PC's. However,
                you can only use 5 devices at one time with one account. If you need more devices, please send us an email at
                <a href={"mailto:support@rascalkids.app"} target={"_blank"}>support@rascalkids.app</a>
              </p>


              <h2 className={"custom-list-title"}>
                4. What languages are available?
              </h2>
              <p>
                Rascal is currently available in English and Te Reo Māori, ready to keep you company and share stories
                in these languages. As our network and library of tales grow, we're eager to explore new horizons and
                bring more languages into our storytelling world. Stay tuned for future linguistic adventures!
              </p>

              <h2 className={"custom-list-title"}>
                5. Who makes the stories?
              </h2>
              <p>
                Rascal Originals, Rascal work's with a range of amazing creatives globally to develop stories perfect for the platform!
                <br />
                Rascal also acquires stories from established / produced 2d animation series or published children's books!
                We're always looking for amazing new stories to host on the Rascal Platform!
              </p>

              <h2 className={"custom-list-title"}>
                6. How can I bring Rascal into my classroom?
              </h2>
              <p>
                If you're interested in bringing Rascal into your classroom, we're thrilled to offer our services to
                schools for free! To get started, simply contact us at <a href={"mailto:schools@rascal.nz"} target={"_blank"}>schools@rascal.nz</a>,
                and we'll assist you in setting up Rascal as an invaluable resource for your classroom or kindergarten.
              </p>

              <h2 className={"custom-list-title"} id={"creator-enquiries"}>
                6. How can I get involved?
              </h2>
              <p>
                Do you have a short animated film already produced?!
                <br />
                Maybe you've made a children's book and would love to see it developed into a readable-game?
                <br />
                Or are you eager to explore creative opportunities with us on our productions?
                <br />
                <br />
                We are always looking for amazing talent and would love to hear from you! Reach out to us at:
                <a href={"mailto:creatives@rascal.nz"} target={"_blank"}>creatives@rascal.nz</a>
                <br />
                <br />
                <b>Please Note:</b> We aren't looking for any submissions of work, just an expression of interest in working together.
                <br />
                <br />
                <b>Unsolicited Submission Policy:</b>
                Rascal does not accept or consider unsolicited ideas, suggestions, information, or materials of any nature
                whatsoever (“submissions”) and we request that you don’t provide submissions to us. The purpose of this
                policy is to avoid misunderstandings and disputes when Rascal stories, animations, and illustrations
                are similar or even identical to your submissions. Submissions to us or any affiliated entity or
                individual, regardless of any statements made in connection with such submission, the following terms apply:

                <ol>
                  <li>
                    Rascal will consider the submission to be non-confidential and non-proprietary.
                  </li>
                  <li>
                    Rascal will have no obligation of any nature whatsoever relating to the submission.
                  </li>
                  <li>
                    The submission automatically becomes property of Rascal. Rascal may use, disclose and distribute
                    the submission in any manner and for any purpose without compensation to you or any third party.
                  </li>
                </ol>
              </p>

              <h2 className={"custom-list-title"}>
                7. You have some thoughts!?
              </h2>
              <p>
                Rascal is ALWAYS looking for feedback, good and bad - we want Rascal to feel like a safe-haven for parents
                and kids, and we can only do that with your input. We want Rascal to be the best storytelling app that we can be, so
                please don't hesitate to reach out and give us your piece of mind <a href={"mailto:support@rascalkids.app"} target={"_blank"}>support@rascalkids.app</a>
              </p>

            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default FAQ;

