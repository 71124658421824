import React from 'react';
import {observer} from 'mobx-react';
import {loadStripe} from '@stripe/stripe-js/pure';
import {StripeElementsOptions} from '@stripe/stripe-js';
import {Elements, ElementsConsumer} from '@stripe/react-stripe-js';

import {ComponentWithStore, withStore} from 'models/RootStore';
import {BillingAddress} from 'models/billingAddress/BillingAddress';
import {SubscriptionPlan} from 'models/subscriptionPlan/SubscriptionPlan';
import {CreditCardApi} from 'models/creditCard/CreditCardApi';

import StripePaymentForm from 'components/public/credit_cards/StripePaymentForm';

import '../stripe.scss'
import './style.scss'
import {LoadingOutlined} from "@ant-design/icons";
import Coupon from "models/coupon/Coupon";

loadStripe.setLoadParameters({advancedFraudSignals: false});

interface IStripeCreditCardProps {
  billingAddress: BillingAddress;
  subscriptionPlan: SubscriptionPlan;
  onSave: (stripeSetupIntentId: string, creditCardId: number) => Promise<any>;
  onCouponAdded: (coupon: Coupon) => Coupon | null;
  editBillingAddress: (event: React.MouseEvent) => void;
  editSubscriptionPlan: (event: React.MouseEvent) => void;
  disableSave?: boolean;
  errorMessage: { name: string, message: string } | null;
}

interface IStripeCreditCardState {
  stripePromise: any;
  options: StripeElementsOptions;
}

const MemoStripePaymentForm = React.memo(StripePaymentForm);

class SetupForm extends ComponentWithStore<IStripeCreditCardProps, IStripeCreditCardState> {
  mounted: boolean = false;

  constructor(props: IStripeCreditCardProps) {
    super(props);

    this.state = {
      stripePromise: null,
      options: null
    };
  }

  public componentDidMount(): void {
    this.mounted = true;
    const {stripePromise} = this.state;

    CreditCardApi.newIntent(this.store.SessionProvider.userId())
      .then(newIntentJson => this.setState({options: {clientSecret: newIntentJson.client_secret}}));

    if (!stripePromise) {
      const stripePromise = loadStripe(process.env.STRIPE_KEY)
      this.setState({
        stripePromise: stripePromise
      });
    }
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public render(): React.ReactElement {
    const {stripePromise, options} = this.state;
    const {
      billingAddress,
      subscriptionPlan,
      onSave,
      onCouponAdded,
      editBillingAddress,
      editSubscriptionPlan,
      disableSave,
      errorMessage
    } = this.props;

    return (
      <Elements stripe={stripePromise} options={options}>
        <ElementsConsumer>
          {({stripe, elements}) => {
            if (!stripe || !options) return this.renderLoadingSpinner();
            return (
              <MemoStripePaymentForm stripe={stripe}
                                     elements={elements}
                                     options={options}
                                     billingAddress={billingAddress}
                                     subscriptionPlan={subscriptionPlan}
                                     onSave={onSave}
                                     onCouponAdded={onCouponAdded}
                                     editBillingAddress={editBillingAddress}
                                     editSubscriptionPlan={editSubscriptionPlan}
                                     disableSave={disableSave}
                                     errorMessage={errorMessage} />
            );
          }}
        </ElementsConsumer>
      </Elements>
    );
  }

  renderLoadingSpinner(): React.ReactElement {
      return (
        <div style={{display: 'flex', minWidth: '100%', justifyContent: 'center'}}>
            <LoadingOutlined style={{ fontSize: 64 }} spin />
        </div>
      );
  }
}

export default SetupForm;
