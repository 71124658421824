import { AbstractStore } from "models/AbstractStore";
import { RootStore } from "models/RootStore";
import { CampaignAPI, ICampaignApiSearchParams } from "models/campaign/CampaignAPI";
import { ICampaignApiData } from "models/campaign/ICampaignApiData";
import { Campaign } from "models/campaign/Campaign";
import { CampaignSearchData, ICampaignSearchApiData } from "models/campaign/ICampaignSearchApiData";


export class CampaignProvider extends AbstractStore {
  public constructor(rootStore: RootStore) {
    super(rootStore, 'Campaign');

    this.apiDataToModel = this.apiDataToModel.bind(this);
    this.apiDataArrayToModelArray = this.apiDataArrayToModelArray.bind(this);
  }

  public getCampaignSearch(searchParams?: ICampaignApiSearchParams): Promise<CampaignSearchData> {
    return CampaignAPI.getCampaignSearch(searchParams)
      .then((data: ICampaignSearchApiData) => ({
        page: data.page,
        page_size: data.page_size,
        total: data.total,
        campaigns: this.apiDataArrayToModelArray(data.campaigns)
      }));
  }

  public getAll(): Promise<Campaign[]> {
    return CampaignAPI.getAll()
      .then(this.apiDataArrayToModelArray);
  }

  private apiDataToModel(campaign: ICampaignApiData): Campaign {
    return new Campaign(this).withData(campaign);
  }

  public apiDataArrayToModelArray(apiCampaign?: ICampaignApiData[]): Campaign[] {
    return apiCampaign ? apiCampaign.map(this.apiDataToModel) : [];
  }
}
