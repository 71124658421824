import React, {Component} from "react";

class PlayIcon extends Component {
  render() {
    return (
      <svg className='rk-icon rk-play-icon' xmlns="http://www.w3.org/2000/svg" width="10.614" height="15" viewBox="0 0 10.614 15">
        <path d="M1203.762,671.4v-.022l0,0a1.364,1.364,0,0,1,2.316-.753l7.856,6.182a1.222,1.222,0,0,1,.009,1.855l-7.855,6.161a1.362,1.362,0,0,1-2.323-.823l-.005-.052C1203.758,683.886,1203.743,671.528,1203.762,671.4Z" transform="translate(-1203.753 -670.224)" fill="currentColor"/>
      </svg>
    );
  }
}

export default PlayIcon;
