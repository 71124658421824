
import React from 'react'

import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom'

import {CookiesProvider} from 'react-cookie';

import Page from './pages/_Page';
import PrivateRoute from '../../routes/PrivateRoutes';
import {ConfigProvider, message} from 'antd';
import RascalTheme from 'components/shared/RascalTheme';
import SessionCookieStore from 'models/session/SessionCookieStore';
import Homepage from 'components/public/Homepage';
import {MessageInstance} from "antd/lib/message/interface";
import About from "components/public/pages/home/public/About";
import PrivacyPolicy from "components/public/pages/home/public/policies/PrivacyPolicy";
import TermsAndConditions from "components/public/pages/home/public/policies/TermsAndConditions";
import CookiePolicy from "components/public/pages/home/public/policies/CookiePolicy";
import ScrollToTop from "helpers/ScrollToTop";
import FAQ from "components/public/pages/home/public/FAQ";
import SEO from "helpers/SEO";

export interface IAppProps {
    currentUserId: number | null;
}

export interface IAppState {

}

declare global {
  interface Window {
    messageApi: MessageInstance;
  }
}

export const MessageContext = () => {
    const [messageApi, contextHolder] = message.useMessage();
    window.messageApi = messageApi;
    return (
        <React.Fragment>
            {contextHolder}
        </React.Fragment>
    )
}

class App extends React.Component<IAppProps, IAppState> {

    public static isAdmin(): boolean {
        return false;
    }

    public static loadUser(userId: number | null) {
        if (userId) {
            SessionCookieStore.store({
                user_id: userId
            });
        }
    }

    public static Routes(): React.ReactElement[] {
        return [
            <Route path='/sign_in' key='1' element={<Page.SignIn/>}/>,
            <Route path='/register' key='2' element={<Page.Register/>}/>,
            <Route path='/subscribe' key='7' element={<Page.Register/>}/>,
            <Route path='/change_email' key='3' element={<Page.ChangeEmail/>}/>,
            <Route path='/reset_password' key='4' element={<Page.ResetPassword/>}/>,
            <Route path='/forgot_password' key='5' element={<Page.ForgotPassword/>}/>,
            <Route path='/campaign/:campaign' key='6' element={<Homepage/>}/>,
            <Route path='/about' key='7' element={<About/>}/>,
            <Route path='/privacy-policy' key='8' element={<PrivacyPolicy/>}/>,
            <Route path='/terms-and-conditions' key='9' element={<TermsAndConditions/>}/>,
            <Route path='/cookie-policy' key='10' element={<CookiePolicy/>}/>,
            <Route path='/faq' key='11' element={<FAQ/>}/>,
            <Route path='/manage_data' key='12' element={<Page.StaticManageData/>}/>,
            <Route path='/subscriptions' key='101' element={PrivateRoute(<Page.SubscriptionNew nested={false}/>)}/>,
            <Route path='/subscriptions/:subscriptionId/manage' key='102' element={PrivateRoute(<Page.SubscriptionManage/>)}/>,
            <Route path='/subscriptions/:subscriptionId/manage/users' key='103' element={PrivateRoute(<Page.BulkSubscribe/>)}/>,
            <Route path='/change_subscription/:subscriptionId' key='109' element={PrivateRoute(<Page.SubscriptionNew nested={false}/>)}/>,
            <Route path='/change_card/:subscriptionId' key='110' element={PrivateRoute(<Page.CreditCardNew/>)}/>,
            <Route path='/subscribed' key='108' element={PrivateRoute(<Page.Summary/>)}/>,
            <Route path='/billing' key='102' element={PrivateRoute(<Page.BillingAddress/>)}/>,
            <Route path='/account' key='103' element={PrivateRoute(<Page.AccountSetting/>)}/>,
            <Route path='/account/manage_data' key='103' element={PrivateRoute(<Page.AccountManageData/>)}/>,
            <Route path='/invoices' key='106' element={PrivateRoute(<Page.InvoiceIndex/>)}/>,
            <Route path='/invoices/:invoiceId' key='107' element={PrivateRoute(<Page.InvoiceShow/>)}/>,
            <Route path='/invoices/:invoiceId/authorize' key='101A' element={PrivateRoute(<Page.AuthorizePayment/>)}/>,
            <Route path='/library' key='111' element={PrivateRoute(<Page.Catalogue title={'Library'}/>)}/>,
            <Route path='/story/:storyId' key='112' element={PrivateRoute(<Page.StoryDetails/>)}/>,
            <Route path='/download/android' key='113' element={PrivateRoute(<Page.PlayStoreRedirect/>)}/>,
            <Route path='/download/ios' key='114' element={PrivateRoute(<Page.AppStoreRedirect/>)}/>,
        ];
    }

    public static HomeRoute(): React.ReactElement[] {
        return [
            <Route path='/' key='1' element={<Page.Home />}/>,
            <Route path='/home' key='2' element={<Page.Home/>}/>,
            <Route path='/404' key='3' element={<Page.NotFound/>}/>,
            <Route path='/*' key='999' element={<Navigate to={"/404"} replace={true}/>}/>
        ];
    }

    componentDidMount() {
        App.loadUser(this.props.currentUserId);
    }

    public render(): React.ReactElement {
        return (
            <ConfigProvider theme={RascalTheme}>
                <CookiesProvider>
                    {SEO.DefaultHeaders}
                    <MessageContext />
                    <BrowserRouter>
                        <ScrollToTop />
                        <Routes>
                            {App.Routes().map((route) => route)}
                            {App.HomeRoute().map((route) => route)}
                        </Routes>
                    </BrowserRouter>
                </CookiesProvider>
            </ConfigProvider>
        );
    }
}

export default App
