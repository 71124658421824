import pako from 'pako';

import logger from 'loglevel';
import Config from 'config/Config';

import Api from 'models/Api';
import {AbstractStore} from 'models/AbstractStore';
import {WebglAppApi, WebglAppConfig} from 'models/webgl/WebglAppApi';
import {WebglIDB} from 'models/webgl/WebglIDB';
import {UnityConfig} from "vendor/react-unity-webgl/source/types/unity-config";

export interface IWebglBlobs {
    dataBlob: Blob;
    frameworkBlob: Blob;
    loaderBlob: Blob;
    wasmBlob: Blob;
}

export class WebglProvider extends AbstractStore {
    public constructor(rootStore) {
        super(rootStore, 'WebglProvider');
    }

    public latest(): Promise<{ config: UnityConfig | null; callback: Function | null }> {
        return WebglAppApi.latest(this.SessionProvider.userId())
            .then((response: WebglAppConfig | null) => {
                if (!response) {
                    logger.error('WebglApp: WebglAppApi.latest: Failed to get, did not receive response');
                    return {
                        config: null,
                        callback: null
                    };
                }

                return this.WebglIDB.open()
                    .then(() => this.WebglIDB.requiresUpdate(response.version))
                    .then((requiresUpdate: boolean) => {
                        if (Config.forceCacheUpdate) logger.debug('WebglApp: Force cache update enable, ignoring stored data');

                        if (requiresUpdate || Config.forceCacheUpdate) {
                            return this.fetchAll(response)
                                .then(blobs => this.WebglIDB.storeAll(blobs, response)
                                    .then(() => logger.debug('WebglApp: Successfully stored data'))
                                    .catch(error => {
                                        logger.error('WebglApp: Failed to store data', error);
                                        throw error;
                                    })
                                    .then(() => this.buildConfig(blobs)));
                        } else {
                            return this.WebglIDB.readAll()
                                .then((blobs: IWebglBlobs) => this.buildConfig(blobs));
                        }
                    })
                    .catch(error => {
                        logger.error('WebglApp: Failed to open database', error);
                        return this.fetchAll(response)
                            .then(blobs => this.buildConfig(blobs));
                    })
                    .then((config: UnityConfig) => {
                        return {
                            config: config,
                            callback: () => {
                                console.log('unityContext.addEventListener(loaded)');
                                return this.revokeConfigUrls(config);
                            }
                        };
                    });
            })
    }

    private static isCompressedURL(url: string): boolean {
        return url.split('?')[0].endsWith('.gz')
    }

    private static uncompress(blob: Blob, type: string): Promise<Blob> {
        return blob.arrayBuffer()
            .then((arrayBuffer: ArrayBuffer) => pako.ungzip(new Uint8Array(arrayBuffer)))
            .then((binaryData: Uint8Array) => new Blob([binaryData], {type: type}));
    }

    private static uncompressedGzip(url: string, blob: Blob, type: string): Promise<Blob> {
        return WebglProvider.isCompressedURL(url) ? this.uncompress(blob, type) : Promise.resolve(blob);
    }

    public fetchAll(config: WebglAppConfig): Promise<IWebglBlobs> {
        let blobs: IWebglBlobs = {
            dataBlob: null,
            frameworkBlob: null,
            loaderBlob: null,
            wasmBlob: null
        };

        return Promise.all([
            this.fetchData(config.data).then((blob: Blob) => blobs.dataBlob = blob),
            this.fetchFramework(config.framework).then((blob: Blob) => blobs.frameworkBlob = blob),
            this.fetchLoader(config.loader).then((blob: Blob) => blobs.loaderBlob = blob),
            this.fetchWasm(config.wasm).then((blob: Blob) => blobs.wasmBlob = blob)
        ]).then(() => blobs);
    }

    private fetchData(url: string): Promise<Blob> {
        return fetch(url)
            .then((response: Response) => response.blob())
            .then((blob: Blob) => WebglProvider.uncompressedGzip(url, blob, WebglIDB.FILE_DATA_TYPES.data.content_type));
    }

    private fetchFramework(url: string): Promise<Blob> {
        return fetch(url)
            .then((response: Response) => response.blob())
            .then((blob: Blob) => WebglProvider.uncompressedGzip(url, blob, WebglIDB.FILE_DATA_TYPES.framework.content_type));
    }

    private fetchLoader(url: string): Promise<Blob> {
        return fetch(url)
            .then((response: Response) => response.blob());
    }

    private fetchWasm(url: string): Promise<Blob> {
        return fetch(url)
            .then((response: Response) => response.blob())
            .then((blob: Blob) => WebglProvider.uncompressedGzip(url, blob, WebglIDB.FILE_DATA_TYPES.wasm.content_type));
    }

    private buildConfig(blobs: IWebglBlobs): UnityConfig {
        let url = window.URL || window.webkitURL;

        return {
            dataUrl: url.createObjectURL(blobs.dataBlob),
            frameworkUrl: url.createObjectURL(blobs.frameworkBlob),
            loaderUrl: url.createObjectURL(blobs.loaderBlob),
            codeUrl: url.createObjectURL(blobs.wasmBlob)
        };
    }

    private revokeConfigUrls(config: UnityConfig): void {
        let url = window.URL || window.webkitURL;
        Object.values(config).forEach((objectUrl: string) => {
            url.revokeObjectURL(objectUrl)
        });
    }
}
