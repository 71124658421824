import {RootStore} from 'models/RootStore';
import {IExposedPermanentStore, PermanentStore} from 'models/PermanentStore';
import {IBillingAddressApiData} from 'models/billingAddress/IBillingAddressApiData';

export class BillingAddressPermanentStore extends PermanentStore implements IExposedPermanentStore<IBillingAddressApiData[]> {
  constructor(rootStore: RootStore) {
    super(rootStore, 'BillingAddress', true);
  }

  public getKey(id: number): string {
    return `api_billing_addresses_${id}`;
  }

  public store(data: IBillingAddressApiData[], id: number): Promise<void> {
    return this.doStore<IBillingAddressApiData[]>(this.getKey(id), data);
  }

  public read(id: number): Promise<IBillingAddressApiData[] | null> {
    return this.doRead<IBillingAddressApiData[]>(this.getKey(id));
  }
}
