import {AbstractStore} from 'models/AbstractStore';
import {RootStore} from 'models/RootStore';
import {Universe} from 'models/universe/Universe';
import {UniverseApi} from 'models/universe/UniverseApi';
import {
    IUniverseApiCreateRequest,
    IUniverseApiResponseData,
    IUniverseApiUpdateRequest
} from 'models/universe/IUniverseApiData';

export class UniverseProvider extends AbstractStore {
    public constructor(rootStore: RootStore) {
        super(rootStore, 'Universe');

        this.apiDataArrayToModelArray = this.apiDataArrayToModelArray.bind(this);
        this.apiDataToModel = this.apiDataToModel.bind(this);
    }

    public getAll(): Promise<Universe[]> {
        return UniverseApi.getAll()
            .then((universes: IUniverseApiResponseData[]) => this.apiDataArrayToModelArray(universes));
    }

    public create(data: IUniverseApiCreateRequest): Promise<Universe> {
        return UniverseApi.create(data)
            .then((apiData: IUniverseApiResponseData) => this.apiDataToModel(apiData));
    }

    public update(universeId: number, data: IUniverseApiUpdateRequest, universe: Universe = new Universe(this)): Promise<Universe> {
        return UniverseApi.update(universeId, data)
            .then((apiData: IUniverseApiResponseData) => this.apiDataToModel(apiData, universe));
    }
    private apiDataArrayToModelArray(universes: IUniverseApiResponseData[]): Universe[] {
        return universes.map((apiData: IUniverseApiResponseData) => this.apiDataToModel(apiData));
    }

    private apiDataToModel(apiData: IUniverseApiResponseData, universe: Universe = new Universe(this)): Universe {
        return universe.withData(apiData);
    }
}
