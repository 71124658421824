import logger from 'loglevel';
import {AbstractStore} from 'models/AbstractStore';
import {RootStore} from 'models/RootStore';
import {IndexedDBWrapper} from 'models/IndexedDBWrapper';
import {LocalStoreWrapper} from 'models/LocalStoreWrapper';

export interface IExposedPermanentStore<T> {
    getKey(id: number): string;

    store(data: T, id?: number): Promise<void>;

    read(id: number): Promise<T | null>;
}

export interface IPermanentStore {
    doStore<T>(key: string, value: T, expiresInMinutes?: number): Promise<void>;

    doRead<T>(key: string): Promise<T | null>;

    clear(): void;
}

export abstract class PermanentStore extends AbstractStore implements IPermanentStore {
    private _store: IndexedDBWrapper | LocalStoreWrapper;
    private _promiseToBeOpened: Promise<IndexedDBWrapper | LocalStoreWrapper> = null;
    private _opened: boolean = false;
    private readonly _json: boolean = false;

    protected constructor(rootStore: RootStore, storeName: string, json: boolean = false) {
        super(rootStore, `Permanent.${storeName}`);
        this._json = json;
    }

    private getStore(): Promise<IndexedDBWrapper | LocalStoreWrapper> {
        if (this._opened) return Promise.resolve(this._store);

        if (this._promiseToBeOpened === null) {
            this._store = new IndexedDBWrapper(this.RootStore, this.StoreName, this._json);
            this._promiseToBeOpened = this._store.open()
                .then(() => {
                    this._opened = true;
                    logger.info(`Successfully opened IDB for ${this.StoreName}`);
                })
                .catch(() => {
                    this._store = new LocalStoreWrapper(this.RootStore, this.StoreName);
                    this._opened = true;
                    logger.info(`Successfully created LocalStore for ${this.StoreName}`)
                })
                .catch(() => {
                    logger.warn(`Failed to create IDB and LocalStore for ${this.StoreName}`)
                })
                .then(() => this._store);
        }

        return this._promiseToBeOpened;
    }

    public static getExpiresAt(expiresInMinutes: number): number {
        return Date.now() + (expiresInMinutes * 60 * 1000);
    }

    public static hasExpired(expiresAt: number): boolean {
        return Date.now() > expiresAt;
    }

    public doStore<T>(key: string, value: T, expiresInMinutes?: number): Promise<void> {
        return this.getStore()
            .then(store => store.doStore(key, value, expiresInMinutes));
    }

    public doRead<T>(key: string): Promise<T | null> {
        return this.getStore()
            .then(store => store.doRead<T>(key)
                .then((result: T | null) => result));
    }

    public clear(): void {
        this.getStore()
            .then(store => store.clear());
    }
}
