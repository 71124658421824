import React, {Component, createRef} from "react";
import AppInfo from "models/AppInfo";
import {Story} from "models/story/Story";
import LanguageSelection from "components/shared/LanguageSelection";
import {Settings} from "models/settings/Settings";
import StoryDetailsToggle from "components/public/pages/webgl_app/StoryDetailsToggle";
import {ComponentWithStore} from "models/RootStore";
import {observable} from "mobx";
import {observer} from "mobx-react";
import SoundIcon from "components/shared/icons/SoundIcon";
import RestartIcon from "components/shared/icons/RestartIcon";
import PlayIcon from "components/shared/icons/PlayIcon";
import ExpandOutlined from "@ant-design/icons/lib/icons/ExpandOutlined";


interface IUnityWrapperProps {
  story: Story;
  onToggleFullscreen: () => void;
  onRestart: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  onMute: (enabled: boolean, event?: React.MouseEvent<HTMLButtonElement>) => void;
  onNarrationChange: (enabled: boolean, event?: React.MouseEvent<HTMLButtonElement>) => void;
  onChangeLanguage: (value: string) => void;
  children: React.ReactNode;
}

interface IUnityWrapperState {
  showMenu: boolean;
  fullscreen: boolean;
}

export class UnityWrapper extends ComponentWithStore<IUnityWrapperProps, IUnityWrapperState> {

  private clicks: number = 0;
  private static TOUCHED_DELAY: number = 500;
  private static TAP_SPEED: number = 200;
  private lastTouchedAt: number = Date.now();

  private timeout = null;

  private wrapperRef: React.RefObject<HTMLDivElement> = createRef();

  private mounted: boolean = false;

  @observable private settings: Settings = new Settings(this.store.SettingsProvider).withDefaultValues(this.store.SessionProvider.userId());

  constructor(props: IUnityWrapperProps) {
    super(props);
    this.captureTap = this.captureTap.bind(this);
    this.setFullscreen = this.setFullscreen.bind(this);
    this.requestFullscreen = this.requestFullscreen.bind(this);
    this.cancelFullscreen = this.cancelFullscreen.bind(this);
    this.isFullscreen = this.isFullscreen.bind(this);

    this.state = {
      showMenu: false,
      fullscreen: false
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.store.SettingsProvider.localSettings().then((settings) => {
      this.settings = settings;
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  public isFullscreen() {
    return this.state.fullscreen;
  }

  public setFullscreen(fullscreen: boolean): void {
    if (this.state.fullscreen !== fullscreen && this.mounted) {
      this.setState({fullscreen: fullscreen});
    }
  }

  public requestFullscreen(): Promise<void> {
      return this.wrapperRef?.current?.requestFullscreen();
  }

  public cancelFullscreen(): Promise<void> {
    if (document.fullscreenElement !== null) {
      return document.exitFullscreen();
    } else {
      return Promise.resolve();
    }
  }

  private captureTap(event: React.MouseEvent<HTMLDivElement>) {
    this.handleTap(event);
    return false;
  }

  private handleTap(event: React.MouseEvent<HTMLDivElement>) {
    const {showMenu} = this.state;
    if (showMenu) {
      if (!this.recentlyTouched()) {
        this.setState({showMenu: false});
      }
      return;
    }

    this.clicks += 1;
    this.lastTouchedAt = Date.now();
    if (this.clicks >= 3) {
      this.setState({showMenu: true});
    }
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.clicks = 0;
    }, UnityWrapper.TAP_SPEED);
  }

  private recentlyTouched(): boolean {
    return this.lastTouchedAt + UnityWrapper.TOUCHED_DELAY > Date.now();

  }

  public render(): React.ReactElement {
    const {story, onRestart, onChangeLanguage, onNarrationChange, onToggleFullscreen, onMute} = this.props;
    const {showMenu, fullscreen} = this.state;
    if (AppInfo.isMobile()) {
      return <div className='rk-webgl-app--container'></div>;
    }

    return (
      <div ref={this.wrapperRef} className={`rk-webgl-app--container ${fullscreen ? 'rk-fullscreen' : ''}`} onMouseDown={this.captureTap}>
        {this.props.children}
        <div className={'rk-webgl__popup-menu__container'}>
          <div className={`rk-webgl__popup-menu ${showMenu ? 'visible' : ''}`} onMouseDown={(event) => {
            event.stopPropagation();
            event.preventDefault();
            return true;
          }}>
            <div>
              <h3>{story.title()}</h3>
            </div>
            <div className={'rk-webgl__popup-menu__content'}>
              <div className={'rk-webgl__popup-menu__row two-columns'}>
                <div className={'rk-webgl__popup-menu__column'} onClick={() => this.setState({showMenu: false})}>
                  <div className={'rk-popup-option link-pointer'}><PlayIcon /> Resume</div>
                </div>
                <div className={'rk-webgl__popup-menu__column column-right'} onClick={() => onRestart()}>
                  <div className={'rk-popup-option link-pointer'}><RestartIcon /> Restart Story</div>
                </div>
              </div>
              <div className={'rk-webgl__popup-menu__row toggle'}>
                <StoryDetailsToggle label='Music'
                  icon={<SoundIcon />}
                  cursor='pointer'
                  isOn={() => this.settings.is_music_on}
                  getValue={(settings: Settings) => settings.is_music_on}
                  onChangeSwitch={onMute}/>
              </div>
              <div className={'rk-webgl__popup-menu__row toggle'}>
                <StoryDetailsToggle label='Narration'
                  icon={<SoundIcon />}
                  cursor='pointer'
                  isOn={() => this.settings.is_narration_on}
                  getValue={(settings: Settings) => settings.is_narration_on}
                  onChangeSwitch={onNarrationChange}/>
              </div>
              <div className={'rk-webgl__popup-menu__row two-columns'}>
                <div className={'rk-webgl__popup-menu__column'}>
                  <div>Language</div>
                </div>
                <div className={'rk-webgl__popup-menu__column column-right'}>
                  <LanguageSelection defaultLanguage={this.settings.language} onChange={onChangeLanguage}/>
                </div>
              </div>
              <div className={'rk-webgl__popup-menu__filler'}/>
            </div>
            <div className={'rk-webgl__popup-menu__footer'}>
              <div className={'link-pointer'} onClick={() => onToggleFullscreen()}>
                <ExpandOutlined /> {fullscreen ? 'Exit Fullscreen' : 'Fullscreen'}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default observer(UnityWrapper);
