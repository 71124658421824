import React, {ChangeEvent, Component} from "react";
import {Button, Input, InputRef} from "antd";
import CouponApi from "models/coupon/CouponApi";
import {ICouponApiData} from "models/coupon/ICouponApiData";
import Coupon from "models/coupon/Coupon";

import "./CouponForm.scss";

interface ICouponFormProps {
  subscriptionPlanId: number;
  onCouponAdded: (coupon: Coupon) => Coupon | null;
}

interface ICouponFormState {
  coupon: Coupon | null;
  loaded: boolean;
  addCoupon: boolean;

  errorMsg: string;
}

class CouponForm extends Component<ICouponFormProps, ICouponFormState> {

  private mounted: boolean = false;
  private code: string;

  constructor(props: ICouponFormProps) {
    super(props);

    this.removeCoupon = this.removeCoupon.bind(this);

    this.state = {
      coupon: null,
      loaded: false,
      addCoupon: false,
      errorMsg: null
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.checkIfCampaignHasCoupon();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  checkIfCampaignHasCoupon() {
    const {subscriptionPlanId} = this.props;
    CouponApi.getUserCampaignCoupon(subscriptionPlanId).then((couponData: ICouponApiData) => {
      if (this.mounted && couponData?.code != null) {
        const coupon = new Coupon().withData(couponData);
        this.setState({coupon: coupon, loaded: true});
      } else if (this.mounted) {
        this.setState({loaded: true})
      }
    });
  }

  checkCode() {
    const {subscriptionPlanId} = this.props;
    this.setState({errorMsg: null});
    CouponApi.check(this.code, subscriptionPlanId).then((couponData: ICouponApiData) => {
      if (this.mounted) {
        if (couponData?.code == null) {
          this.setState({coupon: null});
          this.props.onCouponAdded(null);
        } else {
          const coupon = new Coupon().withData(couponData);
          this.setState({coupon: coupon});
          this.props.onCouponAdded(coupon);
        }
      }
    }).then(() => {
      if (this.mounted) {
        this.setState({addCoupon: false});
      }
    }).catch((error) => {
      this.setState({errorMsg: error.message})
    });
  }

  removeCoupon() {
    this.code = '';
    this.setState({coupon: null});
    this.props.onCouponAdded(null);
  }

  render(): React.ReactElement {
    const {loaded} = this.state;
    if (!loaded) return <React.Fragment/>;
    return (
      <div className={'rk-coupon-form'}>
        <h3>Coupon</h3>
        {this.renderContent()}
      </div>
    );
  }

  renderContent(): React.ReactElement {
    const {coupon, addCoupon, errorMsg} = this.state;
    if (coupon !== null) {
      return (
        <div className={'coupon'}>
          <div className={'coupon__code'}>Code: {coupon.code}</div>
          <div className={"coupon__cancel"} onClick={this.removeCoupon}><a className={'rk-link-blue'}>REMOVE</a></div>
          <div className={'coupon__description'}>{coupon.description}</div>
        </div>
      );
    }
    if (addCoupon) {
      return (
        <div className={'coupon_form'}>
          <div className={'coupon__code-input'}>
            <Input placeholder={'Enter code'} type={'text'} onChange={(evt: React.ChangeEvent<HTMLInputElement>) => this.code = evt.target.value} />
            <div className={'error-message'}>{errorMsg != null && errorMsg}</div>
          </div>
          <div className={'coupon__button'}><Button className={'rk-btn'} onClick={() => this.checkCode()}>save</Button></div>
        </div>
      );
    }

    return (
      <div className={'coupon'} onClick={() => this.setState({addCoupon: true})}>
        <div><a className={'rk-link-blue'}>ADD COUPON</a></div>
      </div>
    )
  }
}

export default CouponForm;
