import {AbstractStore} from 'models/AbstractStore';
import {RootStore} from 'models/RootStore';
import {StorySession} from 'models/storySession/StorySession';
import {StorySessionApi} from 'models/storySession/StorySessionApi';
import {
    IStorySessionApiDataGetResponse,
    IStorySessionApiDataUpdateRequest
} from 'models/storySession/IStorySessionApiData';

export class StorySessionProvider extends AbstractStore {
    public constructor(rootStore: RootStore) {
        super(rootStore, 'StorySession');

        this.apiDataToModel = this.apiDataToModel.bind(this);
    }

    public start(storyId: number, data?: IStorySessionApiDataUpdateRequest): Promise<StorySession> {
        return StorySessionApi.start(storyId, data)
            .then((response: IStorySessionApiDataGetResponse) => {
                return this.StorySessionMemoryStore.store(response, storyId)
                    .then(() => this.apiDataToModel(response));
            });
    }

    public finish(storyId: number): Promise<StorySession> {
        return this.getForStory(storyId)
            .then(storySession => {
                if (storySession?.id) {
                    return this.finishById(storyId, storySession.id);
                }
            });
    }

    private finishById(storyId: number, storySessionId: number): Promise<StorySession> {
        return StorySessionApi.finish(storyId, storySessionId)
            .then((response: IStorySessionApiDataGetResponse) => {
                return this.StorySessionMemoryStore.store(response, storyId)
                    .then(() => this.apiDataToModel(response));
            });
    }

    public exit(storyId: number): Promise<StorySession> {
        return this.getForStory(storyId)
            .then(storySession => {
                if (storySession?.id) {
                    return this.exitById(storyId, storySession.id);
                }
            });
    }

    private exitById(storyId: number, storySessionId: number): Promise<StorySession> {
        return StorySessionApi.exit(storyId, storySessionId)
            .then((response: IStorySessionApiDataGetResponse) => {
                return this.StorySessionMemoryStore.store(response, storyId)
                    .then(() => this.apiDataToModel(response));
            });
    }

    public update(storyId: number, data: IStorySessionApiDataUpdateRequest): Promise<StorySession> {
        return this.getForStory(storyId)
            .then(storySession => !storySession?.id
                ? StorySessionApi.start(storyId, data)
                : StorySessionApi.update(storyId, storySession.id, data))
            .then((response: IStorySessionApiDataGetResponse) => {
                return this.StorySessionMemoryStore.store(response, storyId)
                    .then(() => this.apiDataToModel(response));
            });
    }

    public getForStory(storyId: number | null = null, force: boolean = false): Promise<StorySession | null> {
        if (force) return this.getCurrentSessionForStoryFromApiAndUpdateStore(storyId);

        return this.StorySessionMemoryStore.read(storyId)
            .then((session: IStorySessionApiDataGetResponse) => {
                return session
                    ? this.apiDataToModel(session)
                    : this.getCurrentSessionForStoryFromApiAndUpdateStore(storyId);
            })
            .catch(() => null);
    }

    private getCurrentSessionForStoryFromApiAndUpdateStore(storyId: number | null = null): Promise<StorySession | null> {
        return StorySessionApi.getCurrentSessionForStory(storyId)
          .then((storySession: IStorySessionApiDataGetResponse | null) => {
            if (storySession != null)
              return this.StorySessionMemoryStore.store(storySession, storyId).then(() => this.apiDataToModel(storySession));
            else {
              return null;
            }
          });
    }

    private apiDataToModel(storySession: IStorySessionApiDataGetResponse): StorySession {
        return new StorySession(this).withData(storySession);
    }
}
