import {RootStore} from 'models/RootStore';
import {IExposedPermanentStore, PermanentStore} from 'models/PermanentStore';
import {ISettingsApiData} from 'models/settings/ISettingsApiData';

export class SettingsPermanentStore extends PermanentStore implements IExposedPermanentStore<ISettingsApiData> {
    constructor(rootStore: RootStore) {
        super(rootStore, 'Settings', true);
    }

    public getKey(id: number): string {
        return `api_settings_${id}`;
    }

    public store(data: ISettingsApiData, id: number): Promise<void> {
        return this.doStore<ISettingsApiData>(this.getKey(id), data);
    }

    public read(id: number): Promise<ISettingsApiData | null> {
        return this.doRead<ISettingsApiData>(this.getKey(id));
    }
}
