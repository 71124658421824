import {action, makeObservable, observable} from 'mobx';

import Value from 'helpers/Value';
import {StorySessionProvider} from 'models/storySession/StorySessionProvider';
import {IStorySessionApiDataGetResponse} from 'models/storySession/IStorySessionApiData';

export class StorySession {
    private readonly provider: StorySessionProvider;

    @observable public id: number;

    @observable public story_id: number;
    @observable public started_at: Date;
    @observable public finished_at: Date;
    @observable public exited_at: Date;
    @observable public duration: number;
    @observable public current_asset_bundle: string;
    @observable public current_scene: string;

    constructor(provider: StorySessionProvider) {
        makeObservable(this);

        this.provider = provider;

        this.withId = this.withId.bind(this);
        this.withData = this.withData.bind(this);
    }

    public withId(id: number): StorySession {
        this.id = id;
        return this;
    }

    public withData(data: IStorySessionApiDataGetResponse): StorySession {
        this.load(data);
        return this;
    }

    @action.bound
    public load(data: IStorySessionApiDataGetResponse): void {
        this.id = data.id;
        this.story_id = data.story_id;

        this.started_at = Value.dateOrNull(data.started_at);
        this.finished_at = Value.dateOrNull(data.finished_at);
        this.exited_at = Value.dateOrNull(data.exited_at);
        this.duration = data.duration;
        this.current_asset_bundle = data.current_asset_bundle;
        this.current_scene = data.current_scene;
    }

    public finished(): boolean {
        return this.finished_at != null;
    }
}
