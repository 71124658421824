import {backOff} from 'exponential-backoff';
import logger from 'loglevel';
import {AbstractStore} from 'models/AbstractStore';
import {RootStore} from 'models/RootStore';
import {ApiResponseData} from 'models/ApiResponseData';
import SessionCookieStore from 'models/session/SessionCookieStore';
import {SessionApi} from 'models/session/SessionApi';

export class SessionProvider extends AbstractStore {
    public constructor(rootStore: RootStore) {
        super(rootStore, 'SessionProvider');
    }

    public userId(): number {
        return SessionCookieStore.userId();
    }

    public hasCredentials(): boolean {
        return SessionCookieStore.hasCredentials();
    }

    public clear(): void {
        logger.error('SessionProvider', 'clear()', 'Removing Session Cookie');
        return SessionCookieStore.clear();
    }

    public store(response): ApiResponseData {
        return SessionCookieStore.store(response);
    }

    private static logRetry(method: string): (e: any, attemptNumber: number) => boolean {
        return (e: any, attemptNumber: number): boolean => {
            logger.error('SessionProvider', method, e, 'retrying', attemptNumber);
            return true;
        };
    }

    public hasValidCredentials(): Promise<boolean> {
        if (SessionCookieStore.hasCredentials()) {
            return backOff(() => SessionApi.hasValidCredentials(), {numOfAttempts: 1})
                .then(result => {
                    if (!result) {
                        logger.error('SessionProvider', 'hasValidCredentials()', 'Session does not have valid credentials');
                        this.clear();
                    }
                    return result;
                })
                .catch(e => {
                    logger.error('SessionProvider', 'hasValidCredentials()', e);
                    this.clear();
                    return false;
                });
        } else {
            return Promise.resolve<boolean>(false);
        }
    }

    public hasValidAdminCredentials(): Promise<boolean> {
        if (SessionCookieStore.hasCredentials()) {
            return backOff(() => SessionApi.hasValidAdminCredentials(), {numOfAttempts: 1})
        } else {
            return Promise.resolve<boolean>(false);
        }
    }

    public authOnly(redirect_url: string = '/sign_in'): void {
        this.hasValidCredentials().then(result => {
            if (!result) {
                window.location.href = redirect_url;
            }
        });
    }

    public adminOnly(redirect_url: string = '/sign_in'): void {
        this.hasValidAdminCredentials()
          .then(result => {
                if (!result) {
                    logger.error('SessionProvider', 'hasValidAdminCredentials()', 'Session does not have valid admin credentials');
                    this.clear();
                    window.location.href = redirect_url;
                }
            })
            .catch(e => {
                logger.error('SessionProvider', 'hasValidAdminCredentials()', e);
                window.location.href = redirect_url;
            })
    }

    public signIn(body): Promise<ApiResponseData> {
        return SessionApi.signIn(body)
            .then(json => SessionCookieStore.store(json));
    }

    public signOut(): Promise<any> {
        logger.info('SessionProvider', 'signOut()');
        return SessionApi.signOut()
            .catch((e) => console.log(e.message))
            .then(() => SessionCookieStore.clear())
            .then(() => super.resetAll());
    }
}
