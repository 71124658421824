import {action, makeObservable, observable} from 'mobx';
import _ from 'lodash';

import Value from 'helpers/Value';
import {StoryReleaseProvider} from 'models/storyRelease/StoryReleaseProvider';
import {AssetBundleManifest} from 'models/storyRelease/AssetBundleManifest';
import {
    IStoryReleaseApiDataCreateRequest,
    IStoryReleaseApiDataGetResponse
} from 'models/storyRelease/IStoryReleaseApiData';

export class StoryRelease {
    private readonly provider: StoryReleaseProvider;

    @observable public id: number;

    @observable public story_id: number;
    @observable public platform: string;
    @observable public major_version: number;
    @observable public minor_version: number;
    @observable public patch_version: number;
    @observable public sdk_major_version: number;
    @observable public sdk_minor_version: number;
    @observable public sdk_patch_version: number;
    @observable public compatible_versions: string[];
    @observable public unity_asset_url: string;
    @observable public unity_asset_manifest: AssetBundleManifest = new AssetBundleManifest();

    @observable public published_at: Date;
    @observable public created_at: Date;

    constructor(provider: StoryReleaseProvider) {
        makeObservable(this);

        this.provider = provider;

        this.withId = this.withId.bind(this);
        this.withData = this.withData.bind(this);
    }

    public withId(id: number): StoryRelease {
        this.id = id;
        return this;
    }

    public withData(data: IStoryReleaseApiDataGetResponse | StoryRelease): StoryRelease {
        this.load(data);
        return this;
    }

    @action.bound
    public load(data: IStoryReleaseApiDataGetResponse | StoryRelease) {
        this.id = data.id;
        this.story_id = data.story_id;

        this.platform = data.platform;
        this.major_version = data.major_version;
        this.minor_version = data.minor_version;
        this.patch_version = data.patch_version;
        this.sdk_major_version = data.sdk_major_version;
        this.sdk_minor_version = data.sdk_minor_version;
        this.sdk_patch_version = data.sdk_patch_version;
        this.compatible_versions = data.compatible_versions;

        this.unity_asset_url = data.unity_asset_url;
        this.unity_asset_manifest = new AssetBundleManifest().withData(data.unity_asset_manifest);

        this.published_at = Value.dateOrNull(data.published_at);
        this.created_at = Value.dateOrNull(data.created_at);
    }

    public published(): boolean {
        return this.published_at != null;
    }

    public version(): string {
        return `${this.major_version}.${this.minor_version}.${this.patch_version}`;
    }

    public sdkVersion(): string {
        return `${this.sdk_major_version}.${this.sdk_minor_version}.${this.sdk_patch_version}`;
    }

    public create(): Promise<any> {
        let storyReleaseApiData: IStoryReleaseApiDataCreateRequest = {
            story_id: this.story_id,
            platform: this.platform,
            major_version: this.major_version,
            minor_version: this.minor_version,
            patch_version: this.patch_version,
            sdk_major_version: this.sdk_major_version,
            sdk_minor_version: this.sdk_minor_version,
            sdk_patch_version: this.sdk_patch_version,
            compatible_versions: this.compatible_versions,
            unity_asset_url: this.unity_asset_url,
            unity_asset_manifest: this.unity_asset_manifest
        }

        return this.provider.create(storyReleaseApiData);
    }

    public setSDKVersion(sdk_major_version: number, sdk_minor_version: number, sdk_patch_version: number): void {
        this.sdk_major_version = sdk_major_version;
        this.sdk_minor_version = sdk_minor_version;
        this.sdk_patch_version = sdk_patch_version;
    }

    public isCompatibleWithSDK(): boolean {
        return this.platform == 'webgl';
    }

    public hasAssetUrl(): boolean {
        let bundles = this.unity_asset_manifest?.asset_bundles;
        let bundlesWithUrl = _.filter(bundles, bundle => !!bundle.url);
        return this.unity_asset_url !== null || (bundles.length > 0 && bundles.length === bundlesWithUrl.length);
    }
}
