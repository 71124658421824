import { useMemo } from "react";

/**
 * The canvas count is used to generate a unique Unity canvas ID.
 */
let unityCanvasCount = 0;

/**
 * The prefix used to generate a unique Unity canvas ID.
 */
const unityCanvasIdPrefix = "react-unity-webgl-canvas";

/**
 * Generates a unique Unity canvas ID. This is used internally by Unity since
 * version 2021.2 to identify the canvas element in the DOM. This is not
 * documented in the Unity documentation, but it is used in the Unity source
 * code.
 * @returns A unique identifier for a Unity canvas element.
 */
const useUnityCanvasId = (): string => {
  /**
   * A unique identifier for a Unity canvas element is memorized.
   */
  const unityCanvasId = useMemo(() => {
    // The Unity canvas ID is generated by concatenating the Unity canvas ID
    // prefix with the canvas count. Every time this value is requested, the
    // canvas count is incremented.
    return [unityCanvasIdPrefix, ++unityCanvasCount].join("-");
  }, []);
  return unityCanvasId;
};

export { useUnityCanvasId };
