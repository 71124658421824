import React from 'react';
import * as _ from 'lodash';
import {action, makeObservable, observable, runInAction} from 'mobx';

import {Hash} from 'types/hash';
import Value from 'helpers/Value';

import {ApiResponseData} from 'models/ApiResponseData';
import {IStoryApiData} from 'models/story/IStoryApiData';
import {StoryProvider} from 'models/story/StoryProvider';
import {StoryLocalisation} from 'models/storyLocalisation/StoryLocalisation';
import {StoryRelease} from 'models/storyRelease/StoryRelease';
import {StorySession} from 'models/storySession/StorySession';
import {IRole} from 'models/story/IStoryUpdateApiData';
import {Collection} from 'models/collection/Collection';
import {Universe} from 'models/universe/Universe';

export class Story {
    private readonly provider: StoryProvider

    @observable public id: number;

    @observable public default_localisation_id: number;
    @observable public default_localisation: StoryLocalisation;
    @observable public localisations: Hash<StoryLocalisation>;
    @observable public coming_soon: boolean;
    @observable public author: string;
    @observable public read_time: string;
    @observable public genre: string;
    @observable public minimum_age: number;
    @observable public related: number[];
    @observable public universe: Universe;

    @observable public published_at: Date;
    @observable public released_at: Date;

    // TODO: Update has_been_read when a story has been read
    @observable public has_been_read: boolean = false;

    @observable public current_release: StoryRelease;

    constructor(provider: StoryProvider) {
        makeObservable(this);

        this.provider = provider;

        this.withId = this.withId.bind(this);
        this.withData = this.withData.bind(this);
    }

    public withId(id: number) {
        this.id = id;
        return this;
    }

    public withData(data: IStoryApiData) {
        this.load(data);
        return this;
    }

    @action.bound
    public withDefaultLocalisation(): Story {
        if (this.default_localisation != null) {
            return this;
        }

        this.provider.StoryLocalisationProvider.getAll(this.id)
            .then((localisations: StoryLocalisation[]) => {
                this.localisations = {};
                localisations.map((localisation: StoryLocalisation) => {
                    this.localisations[localisation.locale] = localisation;

                    if (this.default_localisation_id === localisation.id) {
                        runInAction(() => {
                            this.default_localisation = localisation;
                        })
                    }
                });

                if (this.default_localisation == null) {
                    if (this.default_localisation_id == null) {
                        // Create a new localisation if we dont have one.
                        runInAction(() => {
                            this.default_localisation = new StoryLocalisation(this.provider.StoryLocalisationProvider);
                            this.localisations[this.default_localisation.locale] = this.default_localisation;
                        });
                    } else {
                        // I don't know what to do in this case
                        // If we are here it means we have a default_localisation_id that does not match any of the localisations associated with this story
                    }
                }
            });

        return this;
    }

    @action.bound
    public load(data: IStoryApiData) {
        this.id = data.id;

        this.default_localisation_id = data.default_localisation_id;
        this.published_at = Value.dateOrNull(data.published_at);
        this.released_at = Value.dateOrNull(data.released_at);

        if (data.default_localisation) {
            this.default_localisation = new StoryLocalisation(this.provider.StoryLocalisationProvider).withData(data.default_localisation)
        }

        this.coming_soon = data.coming_soon;
        this.author = data.author;
        this.genre = data.genre;
        if (data.universe) {
            this.universe = new Universe(this.provider.UniverseProvider).withData(data.universe);
        }
        this.read_time = data.read_time;
        this.minimum_age = data.minimum_age;
    }

    @action.bound
    public setValue(field: string, value: any) {
        this[field] = value;
    }

    public create(): Promise<ApiResponseData> {
        return this.provider.create(this);
    }

    public newStoryRelease() {
        let storyRelease = new StoryRelease(this.provider.StoryReleaseProvider);
        storyRelease.story_id = this.id;
        return storyRelease;
    }

    public title() {
        return this.default_localisation?.title || 'Untitled';
    }

    public locale() {
        return this.default_localisation?.locale || '';
    }

    public getData(): void {
        // noinspection JSIgnoredPromiseFromCall
        this.thenGetData();
    }

    public thenGetData(): Promise<Story> {
        return this.provider.get(this.id, this, true);
    }

    public getStoryReleases(force: boolean = false): Promise<StoryRelease[]> {
        if (this.id) {
            return this.provider.StoryReleaseProvider.getAllForStory(this.id, force);
        } else {
            return Promise.reject('Story Id is null');
        }
    }

    public getRelated(): Promise<Story[]> {
        if (this.id) {
            return this.provider.StoryProvider.getRelated(this.id);
        } else {
            return Promise.reject('Story Id is null');
        }
    }

    public getCollections(): Promise<Collection[]> {
        if (this.id) {
            return this.provider.CollectionProvider.getAllFor(this.id);
        } else {
            return Promise.reject('Story Id is null');
        }
    }

    public getCredits(): Promise<IRole[]> {
        return this.provider.getCredits(this.id);
    }

    public getStoryRelease(id: number): Promise<StoryRelease> {
        return this.getStoryReleases(true).then((storyReleases: StoryRelease[]) => {
            for (let i = 0; i < storyReleases.length; i++) {
                const storyRelease = storyReleases[i];
                if (storyRelease.id === id) {
                    return storyRelease;
                }
            }
        })
    }

    public getStorySession(force: boolean): Promise<StorySession | null> {
        return this.provider.StorySessionProvider.getForStory(this.id, force);
    }

    public getSubtitle(): string {
        const SEPARATOR: string = ' • ';

        let items: string[] = [];
        if (this.released_at != null) {
            items.push(this.released_at?.getFullYear().toString());
        }
        if (this.genre) {
            items.push(this.genre);
        }
        if (this.read_time) {
            items.push(this.read_time);
        }

        return _.join(items, SEPARATOR);
    }

    public hasCompatibleOfflineStoryRelease(): boolean {
        // TODO: Calculate if a story has compatible story release assets stored locally
        return false;
    }

    public hasCompatibleOnlineStoryRelease() {
        // TODO: Calculate if a story has compatible story release assets online
        return false;
    }
}
