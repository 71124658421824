import {RootStore} from 'models/RootStore';
import {IExposedPermanentStore, PermanentStore} from 'models/PermanentStore';
import {IUserApiData} from 'models/user/IUserApiData';


export class UserPermanentStore extends PermanentStore implements IExposedPermanentStore<IUserApiData> {
  constructor(rootStore: RootStore) {
    super(rootStore, 'User', true);
  }

  public getKey(id: number): string {
    return `api_users_${id}`;
  }

  public store(data: IUserApiData): Promise<void> {
    return this.doStore<IUserApiData>(this.getKey(data.id), data);
  }

  public read(id: number): Promise<IUserApiData | null> {
    return this.doRead<IUserApiData>(this.getKey(id));
  }
}
