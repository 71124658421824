import logger from 'loglevel';

import {AbstractStore} from 'models/AbstractStore';
import {RootStore} from 'models/RootStore';
import {IPermanentStore, PermanentStore} from 'models/PermanentStore';

export class IndexedDBWrapper extends AbstractStore implements IPermanentStore {
    private static readonly VERSION: number = 2;

    private _db: IDBDatabase;
    private readonly _json: boolean;

    public constructor(rootStore: RootStore, storeName: string, json: boolean = false) {
        super(rootStore, `IndexedDB.${storeName}`)
        this._json = json;
        logger.debug(`RKDB.init: ${storeName}`);
    }

    private static get IndexedDB(): IDBFactory {
        return window['indexedDB'] || window['mozIndexedDB'] || window['webkitIndexedDB'] || window['msIndexedDB'];
    }

    private static get IndexedDBTransaction(): IDBTransaction {
        return window['IDBTransaction'] || window['webkitIDBTransaction'] || window['msIDBTransaction'];
    }

    private static get IndexedDBKeyRange(): IDBKeyRange {
        return window['IDBKeyRange'] || window['webkitIDBKeyRange'] || window['msIDBKeyRange'];
    }

    private static expiryKey(key: string): string {
        return `${key}_expiry`;
    }

    public open(): Promise<void> {
        logger.debug(`${this.StoreName}.open:`);

        let indexedDB: IDBFactory = IndexedDBWrapper.IndexedDB;

        if (!indexedDB) {
            logger.warn(`${this.StoreName}.open: Your browser doesn't support a stable version of IndexedDB.`);
            return Promise.reject();
        }

        return new Promise((resolve, reject) => {
            let dbOpenDBRequest: IDBOpenDBRequest = indexedDB.open(this.StoreName, IndexedDBWrapper.VERSION);

            let _reject = reject;
            setTimeout(() => {
                if (dbOpenDBRequest.readyState === 'pending') {
                    _reject();
                }
            }, 120);

            dbOpenDBRequest.onblocked = () => {
                logger.error(`${this.StoreName}.open: OnBlocked - Failed to load IndexedDB.`);
                reject();
            }

            dbOpenDBRequest.onupgradeneeded = (evt) => {
                logger.debug(`${this.StoreName}.open: OnUpgradeNeeded ${evt.oldVersion} -> ${evt.newVersion}}`);
                this._db = dbOpenDBRequest.result;
                // Create the store only if it is missing... somehow.
                // TODO: We should check with IndexDB standards as the web evolves.
                if (!this._db.objectStoreNames.contains(this.StoreName)) {
                    // Then create a new store.
                    this._db.createObjectStore(this.StoreName);
                }
            };

            dbOpenDBRequest.onsuccess = () => {
                logger.debug(`${this.StoreName}.open: OnSuccess`);
                this._db = dbOpenDBRequest.result;
                resolve();
            };

            dbOpenDBRequest.onerror = e => {
                logger.error(`${this.StoreName}.open: OnError - Failed to load IndexedDB. ${JSON.stringify(e)}`);
                reject(e);
            };
        });
    }

    public doStore<T>(key: string, value: T, expiresInMinutes: number = 60): Promise<void> {
        logger.debug(`RKDB.store: ${this.StoreName}.${key}`);

        return new Promise((resolve, reject) => {
            let transaction: IDBTransaction = this._db.transaction(this.StoreName, 'readwrite');

            transaction.oncomplete = () => {
                logger.debug(`${this.StoreName}.store(${key}): OnSuccess`);
                resolve();
            };

            transaction.onerror = () => {
                logger.error(`${this.StoreName}.store(${key}): OnError`);
                reject();
            };

            transaction.objectStore(this.StoreName).put(this._json ? JSON.stringify(value) : value, key);
            transaction.objectStore(this.StoreName).put(PermanentStore.getExpiresAt(expiresInMinutes), IndexedDBWrapper.expiryKey(key));
        });
    }

    public doRead<T>(key: string): Promise<T> {
        logger.debug(`RKDB.read: ${this.StoreName}.${key}`);

        return new Promise((resolve, reject) => {
            let transaction: IDBTransaction = this._db.transaction(this.StoreName, 'readwrite');
            let dataRequest: IDBRequest;
            let expiryRequest: IDBRequest;

            transaction.oncomplete = () => {
                logger.debug(`${this.StoreName}.read(${key}): OnSuccess`);

                if (PermanentStore.hasExpired(expiryRequest.result)) {
                    resolve(null);
                } else {
                    resolve(dataRequest.result);
                }
            };

            transaction.onerror = () => {
                logger.error(`${this.StoreName}.read(${key}): OnError`);
                reject(transaction.error);
            };

            dataRequest = transaction.objectStore(this.StoreName).get(key);
            expiryRequest = transaction.objectStore(this.StoreName).get(IndexedDBWrapper.expiryKey(key));
        })
            .then((result: T | string) => this._json && typeof result === 'string' ? JSON.parse(result) : result);
    }

    public clear(): void {
        let indexedDB: IDBFactory = IndexedDBWrapper.IndexedDB;

        if (!indexedDB) {
            logger.warn(`${this.StoreName}.clear: Your browser doesn't support a stable version of IndexedDB.`);
            return;
        }

        indexedDB.deleteDatabase(this._db.name);
    }
}
