import * as _ from 'lodash';

import {AbstractStore} from 'models/AbstractStore';
import {RootStore} from 'models/RootStore';
import {Collection} from 'models/collection/Collection';
import {CollectionApi} from 'models/collection/CollectionApi';
import {
    ICollectionApiCreateRequest,
    ICollectionApiResponseData,
    ICollectionApiUpdateRequest
} from 'models/collection/ICollectionApiData';

export class CollectionProvider extends AbstractStore {
    public constructor(rootStore: RootStore) {
        super(rootStore, 'Collection');

        this.apiDataArrayToModelArray = this.apiDataArrayToModelArray.bind(this);
        this.apiDataToModel = this.apiDataToModel.bind(this);
    }

    public get(collectionId: number, collection: Collection = new Collection(this), force: boolean = false): Promise<Collection> {
        if (collectionId == null) return Promise.resolve(null);
        if (force) return this.getFromApiAndUpdateStore(collectionId, collection);

        return this.CollectionMemoryStore.read(collectionId)
            .then((apiData: ICollectionApiResponseData | null) => {
                return apiData
                    ? this.apiDataToModel(apiData)
                    : this.getFromApiAndUpdateStore(collectionId, collection);
            });
    }

    public getAllFor(storyId: number): Promise<Collection[]> {
        if (storyId == null) return Promise.resolve([]);

        return this.getAllFromApiAndUpdateStoreFor(storyId);
    }

    public getAll(): Promise<Collection[]> {
        return this.getAllFromApiAndUpdateStore();
    }

    public create(data: ICollectionApiCreateRequest): Promise<Collection> {
        return CollectionApi.create(data)
            .then((apiData: ICollectionApiResponseData) => this.CollectionMemoryStore.store(apiData, apiData.id)
                .then(() => this.apiDataToModel(apiData)));
    }

    public update(collectionId: number, data: ICollectionApiUpdateRequest, collection: Collection = new Collection(this)): Promise<Collection> {
        return CollectionApi.update(collectionId, data)
            .then((apiData: ICollectionApiResponseData) => this.CollectionMemoryStore.store(apiData, apiData.id)
                .then(() => this.apiDataToModel(apiData, collection)));
    }

    private getAllFromApiAndUpdateStoreFor(storyId: number): Promise<Collection[]> {
        return CollectionApi.getAllForStory(storyId)
            .then((collections: ICollectionApiResponseData[]) => Promise.all(_.map(collections, apiData => this.CollectionMemoryStore.store(apiData, apiData.id)))
                .then(() => this.apiDataArrayToModelArray(collections)));
    }

    private getAllFromApiAndUpdateStore(): Promise<Collection[]> {
        return CollectionApi.getAll()
            .then((collections: ICollectionApiResponseData[]) => Promise.all(_.map(collections, apiData => this.CollectionMemoryStore.store(apiData, apiData.id)))
                .then(() => this.apiDataArrayToModelArray(collections)));
    }

    private getFromApiAndUpdateStore(collectionId: number, collection: Collection = new Collection(this)): Promise<Collection> {
        return CollectionApi.get(collectionId)
            .then((apiData: ICollectionApiResponseData) => this.CollectionMemoryStore.store(apiData, apiData.id)
                .then(() => this.apiDataToModel(apiData, collection)));
    }

    private apiDataArrayToModelArray(collections: ICollectionApiResponseData[]): Collection[] {
        return collections.map((apiData: ICollectionApiResponseData) => this.apiDataToModel(apiData));
    }

    private apiDataToModel(apiData: ICollectionApiResponseData, collection: Collection = new Collection(this)): Collection {
        return collection.withData(apiData);
    }
}
