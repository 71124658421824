import {AbstractStore} from 'models/AbstractStore';
import {RootStore} from 'models/RootStore';
import {SubscriptionPlan} from 'models/subscriptionPlan/SubscriptionPlan';
import {ISubscriptionPlanApiData} from 'models/subscriptionPlan/ISubscriptionPlanApiData';
import {SubscriptionPlanApi} from 'models/subscriptionPlan/SubscriptionPlanApi';
import {ISubscriptionPlanUpdateApiData} from 'models/subscriptionPlan/ISubscriptionPlanUpdateApiData';
import {ISubscriptionPlanKeyApiData} from 'models/subscriptionPlan/ISubscriptionPlanKeyApiData';
import {Subscription} from "models/subscription/Subscription";
import {SubscriptionApi} from "models/subscription/SubscriptionApi";

export class SubscriptionPlanProvider extends AbstractStore {
  public constructor(rootStore: RootStore) {
    super(rootStore, 'SubscriptionPlan');

    this.apiDataArrayToModelArray = this.apiDataArrayToModelArray.bind(this);
    this.apiDataToModel = this.apiDataToModel.bind(this);
  }

  public get(id: number, force: boolean = false): Promise<SubscriptionPlan> {
    if (force) return this.getFromApiAndUpdateStore(id);

    return this.SubscriptionPlanMemoryStore.read(id)
      .then((apiData: ISubscriptionPlanApiData | null) => {
        return apiData
          ? this.apiDataToModel(apiData)
          : this.getFromApiAndUpdateStore(id);
      });
  }

  public current(): Promise<SubscriptionPlan> {
    return this.getCurrentFromApiAndUpdateStore();
  }

  public fetchAll(): Promise<SubscriptionPlan[]> {
    return this.getAllFromApiAndUpdateStore();
  }

  public fetchAllForUser(user_id: number = this.SessionProvider.userId()): Promise<SubscriptionPlan[]> {
      if (user_id == null) return this.fetchAll();
      return SubscriptionPlanApi.getAllValid().then(this.apiDataArrayToModelArray);
  }

  public fetchAllAdmin(): Promise<SubscriptionPlan[]> {
    return this.getAllAdminFromApiAndUpdateStore();
  }

  public create(data: { subscription_plan: ISubscriptionPlanUpdateApiData }): Promise<ISubscriptionPlanApiData> {
    return SubscriptionPlanApi.create(data);
  }

  public update(id: number, data: ISubscriptionPlanUpdateApiData): Promise<ISubscriptionPlanApiData> {
    return SubscriptionPlanApi.update(id, data);
  }

  public getKeys(id: number): Promise<ISubscriptionPlanKeyApiData[]> {
    return SubscriptionPlanApi.getKeys(id);
  }

  public generateKeys(id: number, amount: number): Promise<{ keys: ISubscriptionPlanKeyApiData[], key_required: boolean }> {
    return SubscriptionPlanApi.generateKeys(id, amount);
  }

  public activateKeys(id: number, keys: string[]): Promise<ISubscriptionPlanKeyApiData[]> {
    return SubscriptionPlanApi.activateKeys(id, keys);
  }

  public deactivateKeys(id: number, keys: string[]): Promise<ISubscriptionPlanKeyApiData[]> {
    return SubscriptionPlanApi.deactivateKeys(id, keys);
  }

  private getFromApiAndUpdateStore(id: number): Promise<SubscriptionPlan> {
    return SubscriptionPlanApi.get(id)
      .then((plan: ISubscriptionPlanApiData) => this.SubscriptionPlanMemoryStore.store(plan)
        .then(() => this.apiDataToModel(plan)));
  }

  private getCurrentFromApiAndUpdateStore(): Promise<SubscriptionPlan> {
    return SubscriptionPlanApi.getCurrentPlan().then((plan: ISubscriptionPlanApiData) => this.SubscriptionPlanMemoryStore.store(plan)
        .then(() => this.apiDataToModel(plan)));
  }

  private getAllFromApiAndUpdateStore(): Promise<SubscriptionPlan[]> {
    return SubscriptionPlanApi.getAll()
      .then((plans: ISubscriptionPlanApiData[]) => this.SubscriptionPlanMemoryStore.storeAll(plans)
        .then(() => this.apiDataArrayToModelArray(plans)));
  }

  private getAllAdminFromApiAndUpdateStore(): Promise<SubscriptionPlan[]> {
    return SubscriptionPlanApi.getAllAdmin()
      .then((plans: ISubscriptionPlanApiData[]) => this.SubscriptionPlanMemoryStore.storeAll(plans)
        .then(() => this.apiDataArrayToModelArray(plans)));
  }

  private apiDataArrayToModelArray(apiPlans?: ISubscriptionPlanApiData[]): SubscriptionPlan[] {
    return apiPlans ? apiPlans.map(this.apiDataToModel) : [];
  }

  private apiDataToModel(data: ISubscriptionPlanApiData) {
    return new SubscriptionPlan(this).withData(data);
  }
}
