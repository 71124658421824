import {RootStore} from 'models/RootStore';
import {IExposedPermanentStore, PermanentStore} from 'models/PermanentStore';
import {IStorySessionApiDataGetResponse} from 'models/storySession/IStorySessionApiData';

export class StorySessionPermanentStore extends PermanentStore implements IExposedPermanentStore<IStorySessionApiDataGetResponse> {
    constructor(rootStore: RootStore) {
        super(rootStore, 'StorySession', true);
    }

    public getKey(id: number): string {
        return `api_story_session_${id}`;
    }

    public store(data: IStorySessionApiDataGetResponse, id: number): Promise<void> {
        return this.doStore<IStorySessionApiDataGetResponse>(this.getKey(id), data);
    }

    public read(id: number): Promise<IStorySessionApiDataGetResponse | null> {
        return this.doRead<IStorySessionApiDataGetResponse>(this.getKey(id));
    }
}
