import * as _ from 'lodash';
import logger from 'loglevel';

import {RootStore} from 'models/RootStore';
import {IndexedDBWrapper} from 'models/IndexedDBWrapper';
import {Story} from 'models/story/Story';
import {StoryRelease} from 'models/storyRelease/StoryRelease';
import {AssetBundle} from 'models/storyRelease/AssetBundleManifest';

export class AssetBundleIDB extends IndexedDBWrapper {
    public constructor(rootStore: RootStore) {
        super(rootStore, 'AssetBundle');
    }

    public open(): Promise<void> {
        return super.open();
    }

    private createBlobKey(storyId: number, bundle: AssetBundle): string {
        let keyArray: string[] = ['story', storyId.toString(), 'blob'];
        if (bundle != null) keyArray.splice(2, 0, bundle.name);
        return keyArray.join('_');
    }

    private createVersionKey(storyId: number): string {
        return `story_${storyId}_version`;
    }

    private createAssetUrlKey(storyId: number, bundleName: string): string {
        return `story_${storyId}_asset_url_${bundleName}`;
    }

    private createObjectUrlKey(storyId: number): string {
        return `story_${storyId}_object_url`;
    }

    public storeAll(story: Story, release: StoryRelease, blob: Blob, bundle: AssetBundle): Promise<Blob> {
        return this.storeBlob(story, blob, bundle)
            // Store version last so that we don't update the version unless other data is successfully stored
            .then(() => this.storeVersion(story, release.version()))
            .then(() => {
                logger.debug('Asset: Successfully stored data')
                return blob;
            })
            .catch(error => {
                logger.error('Asset: Failed to store data', error);
                throw error;
            });
    }

    private storeBlob(story: Story, blob: Blob, bundle: AssetBundle) {
        return super.doStore<Blob>(this.createBlobKey(story.id, bundle), blob);
    }

    public readBlob(story: Story, bundle?: AssetBundle): Promise<Blob> {
        let key: string = this.createBlobKey(story.id, bundle);
        return super.doRead<Blob>(key)
            .then((blob: Blob) => {
                console.log(`got blob of length ${blob?.size} from key "${key}"`)
                return blob;
            });
    }

    private readByteArray(story: Story, bundle?: AssetBundle): Promise<ArrayBuffer> {
        return this.readBlob(story, bundle)
            .then(blob => blob.arrayBuffer());
    }

    private storeVersion(story: Story, value: string): Promise<void> {
        return super.doStore<string>(this.createVersionKey(story.id), value);
    }

    public readVersion(story: Story): Promise<string> {
        return super.doRead<string>(this.createVersionKey(story.id));
    }

    public storeAssetUrl(story: Story, value: string, bundleName: string = 'unnamed_bundle'): Promise<string> {
        return super.doStore<string>(this.createAssetUrlKey(story.id, bundleName), value).then(() => value);
    }

    public readAssetUrl(storyId: number, bundleName: string = 'unnamed_bundle'): Promise<string> {
        return super.doRead<string>(this.createAssetUrlKey(storyId, bundleName));
    }

    private storeObjectUrl(story: Story, value: string): Promise<void> {
        return super.doStore<string>(this.createObjectUrlKey(story.id), value);
    }

    public readObjectUrl(storyId: number): Promise<string> {
        return super.doRead<string>(this.createObjectUrlKey(storyId));
    }

    public requiresUpdate(story: Story, release: StoryRelease): Promise<boolean> {
        return this.readVersion(story).then((version: string) => {
            console.log(`release.version() !== version ? ${release.version() !== version} [release.version() = ${release.version()}] [version ${version}]`)
            return release.version() !== version;
        });
    }

    public createObjectUrl(story: Story, bundle?: AssetBundle): Promise<string | null> {
        let url = window.URL || window.webkitURL;

        return this.readBlob(story, bundle)
            .then((blob: Blob) => {
                if (!blob) return null;

                let objectURL: string = url.createObjectURL(blob);

                return this.storeObjectUrl(story, objectURL)
                    .then(() => objectURL);
            });
    }

    public revokeObjectUrl(objectUrl: string): void {
        let url = window.URL || window.webkitURL;

        url.revokeObjectURL(objectUrl)
    }
}
