import {RootStore} from 'models/RootStore';
import {IExposedPermanentStore, PermanentStore} from 'models/PermanentStore';
import { IStoryReleaseApiDataGetResponse } from 'models/storyRelease/IStoryReleaseApiData';

export class StoryReleasePermanentStore extends PermanentStore implements IExposedPermanentStore<IStoryReleaseApiDataGetResponse[]> {
  constructor(rootStore: RootStore) {
    super(rootStore, 'StoryRelease', true);
  }

  public getKey(id: number): string {
    return `api_story_releases_${id}`;
  }

  public store(data: IStoryReleaseApiDataGetResponse[], id: number): Promise<void> {
    return this.doStore<IStoryReleaseApiDataGetResponse[]>(this.getKey(id), data);
  }

  public read(id: number): Promise<IStoryReleaseApiDataGetResponse[] | null> {
    return this.doRead<IStoryReleaseApiDataGetResponse[]>(this.getKey(id));
  }
}
