import React from 'react';
import {makeObservable, observable} from 'mobx';
import {observer} from 'mobx-react';
import {Col, Image, Row} from 'antd';

import {ComponentWithStore, withStore} from 'models/RootStore';
import {Story} from 'models/story/Story';
import {IRole} from 'models/story/IStoryUpdateApiData';
import {StorySession} from 'models/storySession/StorySession';
import AppInfo from 'models/AppInfo';

import ImageWithFallback from 'components/shared/ImageWithFallback';
import Credits from 'components/public/pages/webgl_app/Credits';
import StoryDetailsButton from 'components/public/pages/webgl_app/StoryDetailsButton';
import LoadingOverlay from 'components/public/pages/webgl_app/LoadingOverlay';
import {StoryDetailsEvents} from 'components/public/pages/webgl_app/StoryDetailsEvents';
import StoryTitleImage from 'components/public/pages/webgl_app/StoryTitleImage';
import DownloadIcon from 'components/shared/icons/DownloadIcon';
import PlayIcon from 'components/shared/icons/PlayIcon';
import TickIcon from 'components/shared/icons/TickIcon';
import RascalLogo from 'layouts/headers/RascalLogo';

import './WebglApp.scss'

interface IWebglOverlayProps {
    story: Story;
    storySession: StorySession;
    portrait?: boolean;
}

interface IWebglOverlayState {
    isLoaded: boolean;
    isDownloading: boolean;
    isStartupComplete: boolean;
    loadingState: string;
    errorMessage: string;
    storySession: StorySession;
}

class WebglOverlay extends ComponentWithStore<IWebglOverlayProps, IWebglOverlayState> {
    @observable private credits: IRole[] = [];

    public constructor(props: IWebglOverlayProps) {
        super(props);

        makeObservable(this);

        this.state = {
            isLoaded: false,
            isDownloading: false,
            isStartupComplete: false,
            loadingState: '',
            errorMessage: '',
            storySession: this.props.storySession
        };

        this.onUnityStartupComplete = this.onUnityStartupComplete.bind(this);
        this.onSetLoadingState = this.onSetLoadingState.bind(this);
        this.onSetStorySession = this.onSetStorySession.bind(this);
        this.onSetErrorMessage = this.onSetErrorMessage.bind(this);
    }

    public componentDidMount(): void {
        window.addEventListener(StoryDetailsEvents.UNITY_STARTUP_COMPLETE_EVENT, this.onUnityStartupComplete);
        window.addEventListener(StoryDetailsEvents.SET_LOADING_STATE_EVENT, this.onSetLoadingState);
        window.addEventListener(StoryDetailsEvents.SET_STORY_SESSION_EVENT, this.onSetStorySession);
        window.addEventListener(StoryDetailsEvents.SET_ERROR_MESSAGE_EVENT, this.onSetErrorMessage);
    }

    public componentWillUnmount() {
        window.removeEventListener(StoryDetailsEvents.UNITY_STARTUP_COMPLETE_EVENT, this.onUnityStartupComplete);
        window.removeEventListener(StoryDetailsEvents.SET_LOADING_STATE_EVENT, this.onSetLoadingState);
        window.removeEventListener(StoryDetailsEvents.SET_STORY_SESSION_EVENT, this.onSetStorySession);
        window.removeEventListener(StoryDetailsEvents.SET_ERROR_MESSAGE_EVENT, this.onSetErrorMessage);
    }

    private onUnityStartupComplete(event: Event): void {
        this.setState({isStartupComplete: true});
    }

    private onSetLoadingState(event: Event): void {
        this.setState({loadingState: event['detail']});
    }

    private onSetStorySession(event: Event): void {
        this.setState({storySession: event['detail']});
    }

    private onSetErrorMessage(event: Event): void {
        this.setState({errorMessage: event['detail']});
    }

    private doDownload(storySession?: StorySession): Promise<void> {
        const {story} = this.props;

        return Promise.resolve()
            .then(() => this.setState({isDownloading: true}))
            .then(() => this.store.AssetBundleProvider.downloadAssetBundle(story, storySession))
            .then(() => this.setState({isDownloading: false}));
    }

    private downloadClicked(): void {
        const {isDownloading} = this.state;

        // TODO: Download the whole story when download is clicked
        Promise.resolve()
            .then(() => this.setState({errorMessage: ''}))
            .then(() => {
                if (!isDownloading) return this.doDownload();
            })
            .catch(e => this.setState({errorMessage: e.message}))
            .finally(() => this.setState({isDownloading: false}));
    }

    private playClicked(resume: boolean): void {
        const {story} = this.props;
        const {isDownloading} = this.state;

        if (AppInfo.isIOS()) {
            // @ts-ignore
            window.location = `app.rascalkids.rascal://playstory/${story.id}`
            // @ts-ignore
            setTimeout(() => { window.open('https://apps.apple.com/us/app/rascal/id1612015226') }, 1000);
            return;
        } else if (AppInfo.isAndroid()) {
            // @ts-ignore
            setTimeout(() => {  window.location = `intent://playstory/${story.id}#Intent;scheme=app.rascalkids.rascal;package=app.rascalkids.rascal;end` }, 25);
            return;
        }

        Promise.resolve()
            .then(() => this.setState({errorMessage: ''}))
            .then(() => {
                if (!isDownloading) {
                    return Promise.resolve()
                        .then(() => (!resume || !story?.id) && this.store.StorySessionProvider.getForStory(story.id))
                        .then((storySession: StorySession | null) => {
                            // TODO: Close the previous session potentially? and start a fresh new one.
                            // Also this will screw up if you have watched the same story on different devices with each overriding their session.
                            // We need to rethink how we handle sessions in a larger piece of work.
                            if (!resume && storySession != null) {
                                storySession.current_scene = '';
                                storySession.current_asset_bundle = '';
                            }
                            return this.doDownload(storySession)
                        })
                        .then(() => window.dispatchEvent(new Event(StoryDetailsEvents.START_STORY_EVENT)));
                }
            })
            .catch(e => this.setState({errorMessage: e.message}))
            .finally(() => this.setState({isDownloading: false}));
    }

    private renderResumeButton(): React.ReactElement {
        return (
            <StoryDetailsButton label='Resume'
                                icon={<PlayIcon />}
                                color='white'
                                cursor='pointer'
                                onClick={e => this.playClicked(true)}/>
        );
    }

    private renderPlayButton(): React.ReactElement {
        return (
            <StoryDetailsButton label='Play'
                                icon={<PlayIcon />}
                                color='blue'
                                cursor='pointer'
                                onClick={e => this.playClicked(false)}/>
        );
    }

    private renderDownloadButton(): React.ReactElement {
        return (
            <StoryDetailsButton label='Download'
                                icon={<DownloadIcon />}
                                color='white'
                                cursor='pointer'
                                onClick={e => this.downloadClicked()}/>
        );
    }

    private renderLoadingButton() {
        return (
            <StoryDetailsButton label='Loading...'
                                color='white'
                                loading={true}
                                cursor='progress'/>
        );
    }

    private renderDownloadingButton() {
        return (
            <StoryDetailsButton label='Downloading...'
                                color='white'
                                loading={true}
                                cursor='progress'/>
        );
    }

    private renderDownloadedButton(): React.ReactElement {
        return (
            <StoryDetailsButton label='Downloaded'
                                icon={<TickIcon />}
                                color='inactive'
                                cursor='not-allowed'/>
        );
    }

    private renderIncompatibleButton(): React.ReactElement {
        return (
            <StoryDetailsButton label='Not Compatible'
                                color='inactive'
                                cursor='not-allowed'/>
        );
    }

    private renderButton(): React.ReactElement {
        const {isStartupComplete, isDownloading, storySession} = this.state;

        if (AppInfo.isIOS()) {
            return (
                <Row gutter={[10, 10]}>
                    <Col xs={24}>
                        {this.renderPlayButton()}
                    </Col>
                </Row>
            );
        } else if (AppInfo.isAndroid()) {
            return (
                <Row gutter={[10, 10]}>
                    <Col xs={24}>
                        {this.renderPlayButton()}
                    </Col>
                </Row>
            );
        } else if (!isStartupComplete) {
            return (
                <Row>
                    {this.renderLoadingButton()}
                </Row>
            )
        } else if (isDownloading) {
            return (
                <Row gutter={[10, 10]}>
                    <Col xs={24}>
                        {this.renderDownloadingButton()}
                    </Col>
                </Row>
            )
        } else if (storySession?.current_asset_bundle) {
            return (
                <Row gutter={[10, 10]}>
                    <Col xs={12} md={24}>
                        {this.renderPlayButton()}
                    </Col>
                    <Col xs={12} md={24}>
                        {this.renderResumeButton()}
                    </Col>
                </Row>
            )
        } else {
            return (
                <Row gutter={[10, 10]}>
                    <Col xs={24}>
                        {this.renderPlayButton()}
                    </Col>
                </Row>
            )
        }
    }

    private renderInformation(): React.ReactElement {
        const {story} = this.props;
        const {errorMessage} = this.state;

        let color: string = story.default_localisation?.featured_text_color || '#000';

        return (
            <div className='webgl-overlay--image-text'>
                <div className='webgl-overlay--image-text-top-right'>
                    <RascalLogo color={color} width={96}/>
                </div>
                <Row className='webgl-overlay__gutters webgl-overlay--image-text-bottom'>
                    <div className={'webgl-overlay__blur'}></div>
                    <Col xs={{order: 1, span: 24}}>
                        <StoryTitleImage story={story}/>
                    </Col>
                    <Col xs={{order: 4, span: 24}}
                         md={{order: 3, span: 6}}
                         lg={{order: 3, span: 5}}>
                        {this.renderButton()}
                    </Col>
                    <Col xs={{order: 2, span: 12}}
                         sm={{order: 4, span: 10}}
                         md={{order: 4, span: 10, offset: 1}}
                         lg={{order: 4, span: 12, offset: 1}}
                         className='webgl-overlay--image-text-description-section'>
                        {errorMessage && <p style={{color: 'red'}}>{errorMessage}</p>}
                        <p style={{color: color}} className={`biography ${AppInfo.isMobile() ? 'mobile' : ''}`}>
                            {story.default_localisation?.biography}
                        </p>
                        <p style={{color: color}}>
                            {story.getSubtitle()}
                        </p>
                    </Col>
                    <Col xs={{order: 3, span: 10}}
                         sm={{order: 5, span: 5}}
                         md={{order: 5, span: 5, offset: 1}}
                         className='webgl-overlay--credits-section'>
                        <p className='webgl-overlay--first-credit' style={{color: color}}>
                            Written by <strong>{story.author}</strong>
                        </p>
                        <button className='webgl-overlay--more-credits-button'
                                style={{color: color}}
                                onClick={() => window.dispatchEvent(new Event(StoryDetailsEvents.TOGGLE_CREDITS_VISIBILITY_EVENT))}>
                            More Details
                        </button>
                    </Col>
                </Row>
            </div>
        );
    }

    private renderInformationPortrait(): React.ReactElement {
        const {story} = this.props;
        const {errorMessage} = this.state;

        let color: string = story.default_localisation?.featured_text_color || '#000';

        return (
            <div className='webgl-overlay--image-text-portrait'>
                <div className='webgl-overlay--image-text-top-right'>
                    <RascalLogo color={color} width={96}/>
                </div>
                <Row className='webgl-overlay__gutters webgl-overlay--image-text-bottom' justify='center' align='middle'>
                    <div className={'webgl-overlay__blur'}></div>
                    <Col xs={24}>
                        <StoryTitleImage story={story} center={true}/>
                    </Col>
                    <Col>
                        {this.renderButton()}
                    </Col>
                    <Col xs={24} className='webgl-overlay--image-text-description-section'>
                        {errorMessage && <p style={{color: 'red'}}>{errorMessage}</p>}
                    </Col>
                    <Col className='webgl-overlay--image-text-description-section'>
                        <p style={{color: '#000'}}>
                            {story.getSubtitle()}
                        </p>
                    </Col>
                    <Col xs={24} className='webgl-overlay--image-text-description-section'>
                        <p style={{color: '#000'}}>
                            {story.default_localisation?.biography}
                        </p>
                    </Col>
                    <Col xs={24}>
                        <p className='webgl-overlay--first-credit' style={{color: '#000'}}>
                            Written by <strong>{story.author}</strong>
                        </p>
                        <button className='webgl-overlay--more-credits-button'
                                style={{color: '#000'}}
                                onClick={() => window.dispatchEvent(new Event(StoryDetailsEvents.TOGGLE_CREDITS_VISIBILITY_EVENT))}>
                            More Details
                        </button>
                    </Col>
                </Row>
            </div>
        );
    }

    private renderImageOverlay(): React.ReactElement {
        const {story, portrait} = this.props;
        const url = portrait ? story?.default_localisation?.featured_portrait_image_url : story?.default_localisation?.featured_landscape_image_url;
        const imageClassName = portrait ? 'webgl-overlay--image-portrait' : 'webgl-overlay--image-landscape';
        const containerClassName = portrait ? 'webgl-overlay--portrait-container' : 'webgl-overlay--container';

        return (
            <div className={containerClassName}>
                <ImageWithFallback imageUrl={url} className={imageClassName}/>
                {portrait ? this.renderInformationPortrait() : this.renderInformation()}
            </div>
        );
    }

    public render(): React.ReactElement {
        const {story} = this.props;
        const {isDownloading, loadingState} = this.state;

        return (
            <React.Fragment>
                {loadingState === '' && this.renderImageOverlay()}
                {isDownloading && <LoadingOverlay/>}
                <Credits story={story}/>
            </React.Fragment>
        );
    }
}

export default withStore(observer(WebglOverlay));
