import store, {StoreAPI} from 'store2';

import {AbstractStore} from 'models/AbstractStore';
import {RootStore} from 'models/RootStore';
import {IPermanentStore, PermanentStore} from 'models/PermanentStore';

export class LocalStoreWrapper extends AbstractStore implements IPermanentStore {
    private readonly _localStorage: StoreAPI;

    public constructor(rootStore: RootStore, storeName: string) {
        super(rootStore, `LocalStore.${storeName}`);
        this._localStorage = store.namespace(this.StoreName);
    }

    public doStore<T>(key: string, data: T, expiresInMinutes: number = 60): Promise<void> {
        this._localStorage.set(key, JSON.stringify({
            data: data,
            expiresAt: PermanentStore.getExpiresAt(expiresInMinutes)
        }));

        return Promise.resolve();
    }

    public doRead<T>(key: string): Promise<T | null> {
        let dataString: string | null = this._localStorage.get(key);

        if (!dataString) {
            return Promise.resolve(null);
        }

        let dataObject: { data: T, expiresAt: number } = JSON.parse(dataString);

        if (PermanentStore.hasExpired(dataObject.expiresAt)) {
            return Promise.resolve(null);
        }

        return Promise.resolve(dataObject.data);
    }

    public clear(): void {
        this._localStorage.clear();
    }
}
