import {action, computed, makeObservable, observable} from 'mobx';

import Value from 'helpers/Value';
import {ISettingsApiData} from 'models/settings/ISettingsApiData';
import {SettingsProvider} from 'models/settings/SettingsProvider';

export class Settings {
    private readonly provider: SettingsProvider;

    @observable public user_id: number;

    @observable public is_music_on: boolean;
    @observable public is_narration_on: boolean;
    @observable public language: string;

    constructor(provider: SettingsProvider) {
        makeObservable(this);

        this.provider = provider;

        this.withData = this.withData.bind(this);
    }

    public withData(data: ISettingsApiData): Settings {
        this.load(data);
        return this;
    }

    public toData(): ISettingsApiData {
        return {
            user_id: this.user_id,
            is_music_on: this.is_music_on,
            is_narration_on: this.is_narration_on,
            language: this.language
        };
    }

    @action.bound
    public load(data: ISettingsApiData) {
        this.is_music_on = Value.defaultTrue(data?.is_music_on);
        this.is_narration_on = Value.defaultTrue(data?.is_narration_on);
        this.language = data?.language ?? 'English';

        this.withCurrentUser();
    }

    @action.bound
    public setValue(field: string, value: any) {
        this[field] = value;
    }

    @computed
    public get hasRequiredFields() {
        return true;
    }

    public withCurrentUser(): Settings {
        this.user_id = this.provider.SessionProvider.userId();
        return this;
    }

    public async save(): Promise<void> {
        return this.update();
    }

    public fetchData(): Promise<Settings> {
        return this.provider.get().then(this.withData);
    }

    private update(): Promise<void> {
        return this.provider.update(this)
            .then(json => this.load(json));
    }

    withDefaultValues(user_id: number) {
        this.user_id = user_id;
        this.is_music_on = true;
        this.is_narration_on = true;
        this.language = 'English';
        return this;
    }
}
