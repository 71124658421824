import React from 'react';
import {observer} from 'mobx-react';
import {Button, Col, Row} from 'antd';
import * as _ from 'lodash';

import {ComponentWithStore, withStore} from 'models/RootStore';
import {Story} from 'models/story/Story';
import {Homepage as HomepageModel} from 'models/homepage/Homepage';

import DefaultLayout from 'layouts/DefaultLayout';
import Homepage from 'components/public/Homepage';
import AppStoreLink from 'components/shared/AppStoreLink';
import GooglePlayLink from 'components/shared/GooglePlayLink';
import Catalogue from 'components/public/pages/home/Catalogue';
import LoadingIcon from 'components/public/pages/webgl_app/LoadingIcon';
import StoryMultiPanel from 'components/public/pages/home/StoryMultiPanel';
import StoryTitleImage from 'components/public/pages/webgl_app/StoryTitleImage';

import './Home.scss';
import {observable} from "mobx";

interface IHomeProps {
}

interface IHomeState {
  loaded: boolean;
  loggedIn: boolean;
}

// This renders either itself or Logged out Homepage.
class Home extends ComponentWithStore<IHomeProps, IHomeState> {
  @observable private stories: Story[];
  @observable private homepage: HomepageModel;

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      loggedIn: true
    }
  }

  public signOut(): void {
    this.store.SessionProvider.signOut().then(success => {
      window.location.href = '/sign_in';
    });
  }

  public componentDidMount(): void {
    if (this.store.SessionProvider.hasCredentials()) {
      this.store.SessionProvider.hasValidCredentials()
        .then(result => {
          if (!result) {
            if (this.store.SessionProvider.hasCredentials()) {
              this.store.SessionProvider.clear();
            }
            window.location.href = '/sign_in';
          } else {
            this.store.SubscriptionProvider.hasValidSubscription()
              .then(result => {
                if (!result) {
                  window.location.href = '/register';
                } else {
                  return new HomepageModel(this.store.StoryProvider).fetchData().then((homepage) => {
                    this.homepage = homepage;
                  }).then(() => this.store.StoryProvider.getAll())
                    .then((stories: Story[]) => {
                      this.stories = stories;
                      this.setState({loaded: true});
                  });
                }
              });
          }
        });
    } else {
      this.setState({
        loggedIn: false
      });
    }
  }

  public render(): React.ReactElement {
    if (!this.store.SessionProvider.hasCredentials()) {
      if (!this.state.loggedIn) {
        return <Homepage/>
      }
    }
    const {loaded} = this.state;
    if (!loaded) {
      return (
        <DefaultLayout>
          <div className='rk-home-layout'>
            {this.renderLoading()}
          </div>
        </DefaultLayout>
      );
    }

    return (
      <DefaultLayout>
        <div className='rk-home-layout'>
          {this.renderSplashStory()}
          {this.renderCatalogueRow()}
          {this.renderMultiPanelRow()}
          {this.renderDownloadRow()}
        </div>
      </DefaultLayout>
    );
  }

  private renderSplashStory() {
    const splashStory = this.homepage?.featured_story;
    if (!splashStory || !splashStory.default_localisation) return <React.Fragment/>;

    return (
      <Row className='rk-home--banner'>
        <Col className={'rk-home--banner__col'} md={24} lg={{span: 20, offset: 2}}>
          <div className='rk-center'>
            <img className='rk-home--banner-img landscape'
                 width='100%'
                 src={splashStory.default_localisation?.featured_landscape_image_url || splashStory.default_localisation?.featured_portrait_image_url}/>
            <img className='rk-home--banner-img portrait'
                 width='100%'
                 src={splashStory.default_localisation?.featured_portrait_image_url || splashStory.default_localisation?.featured_landscape_image_url}/>
            <div className='rk-home--banner-text'>
              <StoryTitleImage story={splashStory}/>
              <Button className='rk-home--banner-button' href={`/story/${splashStory.id}`}>
                Play
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    );
  }

  private renderLoading(): React.ReactElement {
    return (
      <div className='rk-catalogue-row-slider' style={{width: '100%', height: '100%'}}>
        <LoadingIcon color='blue'/>
      </div>
    );
  }

  private renderCatalogueRow() {
    const {loaded} = this.state;

    if (!loaded) return this.renderLoading();

    return (
      <div className='rk-home--catalogue'>
        <Catalogue title='New Releases' showMessage={true} stories={this.stories} homepage={true}/>
      </div>
    );
  }

  private renderMultiPanelRow() {
    const {loaded} = this.state;

    if (!loaded) return this.renderLoading();
    return (
      <Row align='middle' className='rk-home--multi-panel'>
        <StoryMultiPanel story={this.homepage?.multi_panel_story}/>
      </Row>
    );
  }

  private renderDownloadRow() {
    return (
      <Row align='middle' className='rk-home--download'>
        <Col xs={24} md={{span: 20, offset: 2}} className='rk-home--download-content'>
          <h2>Download Rascal:</h2>
          <p>
            Welcome to Rascal. Now you can download our app and read our latest stories.
          </p>
          <div className='homepage__info-app-badge'>
            <AppStoreLink/>
            <GooglePlayLink/>
          </div>
        </Col>
      </Row>
    );
  }
}

export default observer(Home);
