import React, {createRef, useCallback, useEffect, useState} from 'react';
import logger from 'loglevel';
import {Select} from 'antd';
import {ExpandOutlined} from '@ant-design/icons';

import Config from 'config/Config';
import {RootStore} from 'models/RootStore';
import {Settings} from 'models/settings/Settings';
import {Story} from 'models/story/Story';
import {Collection} from 'models/collection/Collection';
import {StorySession} from 'models/storySession/StorySession';

import StoryDetailsButton from 'components/public/pages/webgl_app/StoryDetailsButton';
import StoryDetailsToggle from 'components/public/pages/webgl_app/StoryDetailsToggle';
import {StoryDetailsEvents} from 'components/public/pages/webgl_app/StoryDetailsEvents';
import {UnityEvents} from 'components/public/pages/webgl_app/UnityEvents';
import AppInfo from "models/AppInfo";
import UnityWrapperElement, { UnityWrapper } from "components/public/pages/webgl_app/UnityWrapper";
import {Unity, UnityConfig, useUnityContext} from "vendor/react-unity-webgl/source/exports";
import LanguageSelection from "components/shared/LanguageSelection";
import RestartIcon from "components/shared/icons/RestartIcon";
import SoundIcon from "components/shared/icons/SoundIcon";

const REMOTE_UNITY_CONFIG: UnityConfig = {
    dataUrl: '/webgl_app/data',
    frameworkUrl: '/webgl_app/framework',
    loaderUrl: '/webgl_app/loader',
    codeUrl: '/webgl_app/wasm'
};

const LOCAL_UNITY_CONFIG: UnityConfig = {
    dataUrl: '/app/webgl_app/Build/webgl_app.data',
    frameworkUrl: '/app/webgl_app/Build/webgl_app.framework.js',
    loaderUrl: '/app/webgl_app/Build/webgl_app.loader.js',
    codeUrl: '/app/webgl_app/Build/webgl_app.wasm'
};

interface IUnityContextCreatorProps {
    story: Story;
    collections: Collection[];
    setPlay: (callback: () => void) => void;
    config?: UnityConfig;
    revokeConfigUrlsCallback: Function;
    settings: Settings;
    store: RootStore;
}

export function WebglApp(props: IUnityContextCreatorProps): React.ReactElement {
    const {story, setPlay, config, revokeConfigUrlsCallback, settings, store} = props;
    const [storySession, setStorySession] = useState<StorySession>(null);
    const unityWrapper: React.RefObject<UnityWrapper> = createRef<UnityWrapper>();

    const {
        unityProvider,
        requestFullscreen,
        addEventListener,
        removeEventListener,
        sendMessage
    } = useUnityContext(config || (Config.useLocalWebGLBuild ? LOCAL_UNITY_CONFIG : REMOTE_UNITY_CONFIG));

    function updateStorySession(storySession: StorySession): void {
        setStorySession(storySession);
        window.dispatchEvent(new CustomEvent(StoryDetailsEvents.SET_STORY_SESSION_EVENT, {detail: storySession}));
    }

    useEffect(() => {
        store.StorySessionProvider.getForStory(story.id)
            .then(storySession => updateStorySession(storySession));
    }, []);

    const doPlay = useCallback((): void => {
        console.log('Do play called');
        sendMessage('Startup', 'Play');
    }, []);

    useEffect(() => {
        setPlay && setPlay(doPlay);
    }, [setPlay, doPlay]);

    function isMusicOn(): boolean {
        return settings ? settings.is_music_on : true;
    }

    function isNarrationOn(): boolean {
        return settings ? settings.is_narration_on : true;
    }

    function getLanguage(): string {
        return settings ? settings.language : 'English';
    }

    function onFullscreenChange() {
        sendMessage('Story Controls(Clone)', 'OnResize');
        setTimeout(() => sendMessage('Story Controls(Clone)', 'OnResize'), 100);
    }

    // This is called when an event is received from the window.
    function onRemoteFullscreenChange() {
      unityWrapper.current?.setFullscreen(document.fullscreenElement !== null);
      onFullscreenChange();
    }

    function onToggleFullscreen() {
        const isFullscreen = document.fullscreenElement !== null;
        if (isFullscreen) {
            unityWrapper.current?.cancelFullscreen();
        } else {
            unityWrapper.current?.requestFullscreen();
            unityWrapper.current?.setFullscreen(true);
        }
    }

    function onStartupComplete(response: boolean): boolean {
        revokeConfigUrlsCallback?.();

        if (story?.id) {
            logger.debug(`RKStartupComplete: ${response} setting story key ${story?.id}`);
            window.dispatchEvent(new Event(StoryDetailsEvents.UNITY_STARTUP_COMPLETE_EVENT));
            sendMessage('Startup', 'SetStory', story.id.toString());
            sendMessage('Story Controls(Clone)', 'SetIsMusicOn', isMusicOn() ? 1 : 0);
            sendMessage('Story Controls(Clone)', 'SetIsNarrationOn', isNarrationOn() ? 1 : 0);
            window.addEventListener('resize', (): void => sendMessage('Story Controls(Clone)', 'OnResize'));
            document.addEventListener('webkitfullscreenchange', onRemoteFullscreenChange);
            document.addEventListener('mozfullscreenchange', onRemoteFullscreenChange);
            document.addEventListener('fullscreenchange', onRemoteFullscreenChange);
            document.addEventListener('MSFullscreenChange', onRemoteFullscreenChange);
            window.addEventListener('keyup', (e: KeyboardEvent): void => {
                if (e.key === 'Esc' || e.key === 'Escape') onRemoteFullscreenChange();
            });
        } else {
            logger.debug(`RKStartupComplete: ${response} with without story key provided`);
        }
        return response;
    }

    function doStartStory(): void {
        sendMessage('Startup', 'Play');
    }

    useEffect(() => {
            window.addEventListener(StoryDetailsEvents.START_STORY_EVENT, doStartStory);
            return () => window.removeEventListener(StoryDetailsEvents.START_STORY_EVENT, doStartStory);
        },
        [window.addEventListener, window.removeEventListener, doStartStory]
    );

    function downloadSceneBundle(name: string): string {
        console.log(`downloadSceneBundle called for '${name}'`);
        store.AssetBundleProvider.downloadSceneBundle(story, name)
            .then(() => sendMessage('Story Controls(Clone)', 'DownloadComplete', name))
            .catch(e => console.log(e));
        return name;
    }

    useEffect(() => {
            addEventListener(UnityEvents.DOWNLOAD_SCENE_BUNDLE_EVENT, downloadSceneBundle);
            return () => removeEventListener(UnityEvents.DOWNLOAD_SCENE_BUNDLE_EVENT, downloadSceneBundle);
        },
        [addEventListener, removeEventListener, downloadSceneBundle]
    );

    useEffect(() => {
            addEventListener(UnityEvents.STARTUP_COMPLETE_EVENT, onStartupComplete);
            return () => removeEventListener(UnityEvents.STARTUP_COMPLETE_EVENT, onStartupComplete);
        },
        [addEventListener, removeEventListener, onStartupComplete]
    );

    useEffect(() => {
            addEventListener(UnityEvents.START_STORY_SESSION_EVENT, onStartStorySession);
            return () => removeEventListener(UnityEvents.START_STORY_SESSION_EVENT, onStartStorySession);
        },
        [addEventListener, removeEventListener, onStartStorySession]
    );

    useEffect(() => {
            addEventListener(UnityEvents.UPDATE_CURRENT_ASSET_BUNDLE_EVENT, onUpdateCurrentAssetBundle);
            return () => removeEventListener(UnityEvents.UPDATE_CURRENT_ASSET_BUNDLE_EVENT, onUpdateCurrentAssetBundle);
        },
        [addEventListener, removeEventListener, onUpdateCurrentAssetBundle]
    );

    useEffect(() => {
            addEventListener(UnityEvents.UPDATE_CURRENT_SCENE_EVENT, onUpdateCurrentScene);
            return () => removeEventListener(UnityEvents.UPDATE_CURRENT_SCENE_EVENT, onUpdateCurrentScene);
        },
        [addEventListener, removeEventListener, onUpdateCurrentScene]
    );

    useEffect(() => {
            addEventListener(UnityEvents.FINISH_STORY_SESSION_EVENT, onFinishStory);
            return () => removeEventListener(UnityEvents.FINISH_STORY_SESSION_EVENT, onFinishStory);
        },
        [addEventListener, removeEventListener, onFinishStory]
    );

    useEffect(() => {
            addEventListener(UnityEvents.EXIT_EVENT, onClickExitButton);
            return () => removeEventListener(UnityEvents.EXIT_EVENT, onClickExitButton);
        },
        [addEventListener, removeEventListener, onClickExitButton]
    );

    useEffect(() => {
            window.addEventListener(StoryDetailsEvents.EXIT_EVENT, onClickExitButton);
            return () => window.removeEventListener(StoryDetailsEvents.EXIT_EVENT, onClickExitButton);
        },
        [window.addEventListener, window.removeEventListener, onClickExitButton]
    );

    useEffect(() => {
            addEventListener(UnityEvents.DISPLAY_ERROR_EVENT, onUnityError);
            return () => removeEventListener(UnityEvents.DISPLAY_ERROR_EVENT, onUnityError);
        },
        [addEventListener, removeEventListener, onUnityError]
    );

    function onUnityError(message: string) {
        logger.debug(`RKDisplayError: ${message}`);
        window.dispatchEvent(new CustomEvent(StoryDetailsEvents.SET_ERROR_MESSAGE_EVENT, {detail: message}));
        return message;
    }

    function onStorySet(response: boolean): boolean {
        logger.debug(`RKStorySet: ${response}`);
        return response;
    }

    useEffect(() => {
            addEventListener(UnityEvents.STORY_SET_EVENT, onStorySet);
            return () => removeEventListener(UnityEvents.STORY_SET_EVENT, onStorySet);
        },
        [addEventListener, removeEventListener, onStorySet]
    );

    function onProgressStateChange(response: string) {
        logger.debug(`RKProgressStateChange: ${response}`);
        window.dispatchEvent(new CustomEvent(StoryDetailsEvents.SET_LOADING_STATE_EVENT, {detail: response}))
        return response;
    }

    useEffect(() => {
            addEventListener(UnityEvents.PROGRESS_STATE_CHANGE_EVENT, onProgressStateChange);
            return () => removeEventListener(UnityEvents.PROGRESS_STATE_CHANGE_EVENT, onProgressStateChange);
        },
        [addEventListener, removeEventListener, onProgressStateChange]
    );

    function onRestartStory(event?: React.MouseEvent<HTMLButtonElement>): void {
        sendMessage('Story Controls(Clone)', 'RestartStory');
    }

    function onChangeMusicSwitch(checked: boolean, event?: React.MouseEvent<HTMLButtonElement>): void {
        settings.is_music_on = checked;
        // noinspection JSIgnoredPromiseFromCall
        store.SettingsProvider.update(settings);
        sendMessage('Story Controls(Clone)', 'SetIsMusicOn', isMusicOn() ? 1 : 0);
    }

    function onChangeNarrationSwitch(checked: boolean, event?: React.MouseEvent<HTMLButtonElement>): void {
        settings.is_narration_on = checked;
        // noinspection JSIgnoredPromiseFromCall
        store.SettingsProvider.update(settings);
        sendMessage('Story Controls(Clone)', 'SetIsNarrationOn', isNarrationOn() ? 1 : 0);
    }

    function onChangeLanguage(value: string): void {
        settings.language = value;
        // noinspection JSIgnoredPromiseFromCall
        store.SettingsProvider.update(settings);
        sendMessage('Story Controls(Clone)', 'SetLanguage', getLanguage());
    }

    function onStartStorySession(): void {
        if (!story?.id) return;

        if (!storySession?.id) {
            store.StorySessionProvider.start(story.id)
                .then(storySession => updateStorySession(storySession));
        }
    }

    function onUpdateCurrentAssetBundle(current_asset_bundle: string): string {
        if (!current_asset_bundle || !story?.id) return current_asset_bundle;

        store.StorySessionProvider.update(story.id, {current_asset_bundle: current_asset_bundle})
            .then(storySession => updateStorySession(storySession))
        return current_asset_bundle;
    }

    function onUpdateCurrentScene(current_scene: string): string {
        if (!current_scene || !story?.id) return current_scene;

        store.StorySessionProvider.update(story.id, {current_scene: current_scene})
            .then(storySession => updateStorySession(storySession));
        return current_scene
    }

    function onFinishStory(): void {
        if (!story?.id) return;

        store.StorySessionProvider.finish(story.id)
            .then(storySession => updateStorySession(storySession))
    }

    function onClickExitButton(): void {
        console.log('OnClickExitButton', story?.id);
        if (!story?.id) {
            window.location.href = '/';
            return;
        }

        store.StorySessionProvider.exit(story.id)
            .finally(() => window.location.href = '/');
    }

    function renderRestartButton(): React.ReactElement {
        return (
            <StoryDetailsButton label='Restart'
                                icon={<RestartIcon />}
                                color='blue'
                                cursor='pointer'
                                onClick={onRestartStory}/>
        );
    }

    function renderMusicToggle(): React.ReactElement {
        return (
            <StoryDetailsToggle label='Music'
                                cursor='pointer'
                                isOn={isMusicOn}
                                getValue={(settings: Settings) => settings.is_music_on}
                                onChangeSwitch={onChangeMusicSwitch}/>
        );
    }

    function renderNarrationToggle(): React.ReactElement {
        return (
            <StoryDetailsToggle label='Narration'
                                cursor='pointer'
                                isOn={isNarrationOn}
                                getValue={(settings: Settings) => settings.is_narration_on}
                                onChangeSwitch={onChangeNarrationSwitch}/>
        );
    }

    function renderLanguageSelection(): React.ReactElement {
        let defaultValue: string = getLanguage();
        return <LanguageSelection defaultLanguage={defaultValue} onChange={onChangeLanguage}/>;
    }

    function renderRecordButton(): React.ReactElement {
        return (
            <button className='story-view--record-button story-view-button-'>
                <img className='story-view-button--icon'
                     src='/icons/buttons/record-icon.svg'/>
                <label className='story-view-button--text'>
                    Record
                </label>
            </button>
        );
    }

    return (
        <div className='rk-webgl-app'>
            <div className='rk-webgl-app--desktop'>
                <UnityWrapperElement ref={unityWrapper} story={story} onToggleFullscreen={onToggleFullscreen} onRestart={onRestartStory} onMute={onChangeMusicSwitch} onNarrationChange={onChangeNarrationSwitch} onChangeLanguage={onChangeLanguage}>
                  <Unity unityProvider={unityProvider}
                       className='rk-webgl-app--unity' />
                </UnityWrapperElement>
                <div className='rk-webgl-app--button-container'>
                    <div className={'rk-webgl-app--button-container__content'}>
                        {renderMusicToggle()}
                        {renderNarrationToggle()}
                    </div>
                    <div className={'rk-webgl-app--button-container__end'}>
                        {renderLanguageSelection()}
                        <ExpandOutlined className={'rk-fullscreen-button'} onClick={() => {
                            unityWrapper?.current?.requestFullscreen();
                            onRemoteFullscreenChange();
                        }}/>
                    </div>
                </div>
            </div>
        </div>
    );
}
