import Api from 'models/Api';
import {
    IStorySessionApiDataGetResponse,
    IStorySessionApiDataUpdateRequest
} from 'models/storySession/IStorySessionApiData';

export class StorySessionApi {
    public static start(storyId: number, data?: IStorySessionApiDataUpdateRequest): Promise<IStorySessionApiDataGetResponse> {
        console.log(`StorySessionApi.start called with storyId: ${storyId} and data: ${JSON.stringify(data)}`);
        let params = data ? {body: JSON.stringify(data)} : {};
        return Api.post(`/api/v1/stories/${storyId}/story_session/start`, params)
            .then(response => response.json());
    }

    public static finish(storyId: number, storySessionId: number): Promise<IStorySessionApiDataGetResponse> {
        console.log(`StorySessionApi.finish called with storyId: ${storyId} and storySessionId: ${storySessionId}`);
        return Api.post(`/api/v1/stories/${storyId}/story_session/${storySessionId}/finish`)
            .then(response => response.json());
    }

    public static exit(storyId: number, storySessionId: number): Promise<IStorySessionApiDataGetResponse> {
        console.log(`StorySessionApi.exit called with storyId: ${storyId} and storySessionId: ${storySessionId}`);
        return Api.post(`/api/v1/stories/${storyId}/story_session/${storySessionId}/exit`)
            .then(response => response.json());
    }

    public static update(storyId: number, storySessionId: number, data: IStorySessionApiDataUpdateRequest): Promise<IStorySessionApiDataGetResponse> {
        console.log(`StorySessionApi.update called with storyId: ${storyId}, storySessionId: ${storySessionId} and data: ${JSON.stringify(data)}`);
        return Api.put(`/api/v1/stories/${storyId}/story_session/${storySessionId}`, {
            body: JSON.stringify({
                story_session: data
            })
        })
            .then(response => response.json());
    }

    public static getCurrentSessionForStory(storyId: number | null = null): Promise<IStorySessionApiDataGetResponse | null> {
        console.log(`StorySessionApi.getCurrentSessionForStory called with storyId: ${storyId}`);
        return Api.get(`/api/v1/stories/${storyId}/story_session/`)
            .then(response => response.json());
    }
}
