import {action, computed, makeObservable, observable} from 'mobx';
import {AbstractStore} from 'models/AbstractStore';
import {RootStore} from 'models/RootStore';
import {IPermanentStore} from 'models/PermanentStore';

export class IStoreData<T extends any> {
  @observable private _id: number;
  @observable private _data: T | null;
  @observable private _isSet: boolean = false;
  private _store: InMemoryStore<T>;

  public constructor(id: number, data: T, store: InMemoryStore<T>) {
    this._id = id;
    this._data = data;
    this._store = store;
    makeObservable(this);
  }

  @computed
  public get IsSet(): boolean {
    return this._isSet;
  }

  @computed
  public get Data(): T | null {
    return this._data;
  }

  @action
  public setData(value: T) {
    this._data = value;
    this._isSet = true;
  }

  @computed
  public get LoadData(): Promise<T | null> {
    return this.IsSet ? Promise.resolve(this.Data) : this._store.loadFromPermanentStore(this._id).then(() => this.Data);
  }
}

export abstract class InMemoryStore<T> extends AbstractStore {
  protected constructor(rootStore: RootStore, storeName: string) {
    super(rootStore, `InMemory.${storeName}`);
    makeObservable(this);
  }

  protected abstract get PermanentStore(): IPermanentStore;

  public abstract loadFromPermanentStore(id: number): Promise<void>;

  protected abstract getIStoreData(id: number): IStoreData<T>;

  protected abstract set(value: T, id?: number): void;

  public abstract store(data: T, id?: number): void;

  public abstract read(id: number): Promise<T>;

  public resetAll(): void {
    this.PermanentStore.clear();
  }
}
