import {RootStore} from 'models/RootStore';
import {IExposedPermanentStore, PermanentStore} from 'models/PermanentStore';
import {IStoryApiData} from 'models/story/IStoryApiData';

export class StoryPermanentStore extends PermanentStore implements IExposedPermanentStore<IStoryApiData> {
  constructor(rootStore: RootStore) {
    super(rootStore, 'Story', true);
  }

  public getKey(id: number): string {
    return `api_stories_${id}`;
  }

  public store(data: IStoryApiData): Promise<void> {
    return this.doStore<IStoryApiData>(this.getKey(data.id), data);
  }

  public read(id: number): Promise<IStoryApiData | null> {
    return this.doRead<IStoryApiData>(this.getKey(id));
  }
}
