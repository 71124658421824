import {RootStore} from 'models/RootStore';
import {IExposedPermanentStore, PermanentStore} from 'models/PermanentStore';
import {IInvoiceApiData} from 'models/invoice/IInvoiceApiData';

export class InvoicePermanentStore extends PermanentStore implements IExposedPermanentStore<IInvoiceApiData[]> {
    constructor(rootStore: RootStore) {
        super(rootStore, 'Invoice', true);
    }

    public getKey(id: number): string {
        return `api_invoice_${id}`;
    }

    public store(data: IInvoiceApiData[], id: number): Promise<void> {
        return this.doStore<IInvoiceApiData[]>(this.getKey(id), data);
    }

    public read(id: number): Promise<IInvoiceApiData[] | null> {
        return this.doRead<IInvoiceApiData[]>(this.getKey(id));
    }
}
