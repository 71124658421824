import * as _ from 'lodash';
import logger from 'loglevel';
import promiseRetry from 'promise-retry';

import Api from 'models/Api';
import {AbstractStore} from 'models/AbstractStore';
import {AssetBundleApi, AssetUrlEntity} from 'models/assetBundle/AssetBundleApi';
import {Story} from 'models/story/Story';
import {StoryRelease} from 'models/storyRelease/StoryRelease';
import {AssetBundle} from 'models/storyRelease/AssetBundleManifest';
import {ISceneBundle, IStoryBundle} from 'models/storyRelease/IAssetBundleManifest';
import {StorySession} from 'models/storySession/StorySession';

export class AssetBundleProvider extends AbstractStore {
    public constructor(rootStore) {
        super(rootStore, 'AssetBundleProvider');
    }

    public getObjectUrl(storyId: number): Promise<string> {
        return this.AssetBundleIDB.open()
            .then(() => this.AssetBundleIDB.readObjectUrl(storyId));
    }

    public downloadAssetBundle(story: Story, storySession?: StorySession): Promise<string[]> {
        return this.doGetRelease(story)
            .then(release => {
                if (!release) throw new Error('Compatible version could not be found');

                story.current_release = release;

                if (!story.current_release.unity_asset_manifest) throw new Error('No manifest provided');

                story.current_release.unity_asset_manifest.setReleaseId(story.current_release.id);

                let storyBundle: IStoryBundle = story.current_release.unity_asset_manifest.getStoryBundle();
                storyBundle.current_scene_bundle = storySession?.current_asset_bundle || '';

                this.storeStoryBundleInWindow(storyBundle);

                let sceneBundle: ISceneBundle = storyBundle.scene_bundles[0];
                if (storyBundle.current_scene_bundle) {
                    sceneBundle = _.find(storyBundle.scene_bundles, bundle => bundle.name === storyBundle.current_scene_bundle);
                }

                return Promise.all(_.map(sceneBundle.asset_bundles, assetBundle => this.downloadOrLoadBundle(story, assetBundle)
                    .then(blob => this.storeDataInWindow(blob, assetBundle.name))));
            })
    }

    public downloadSceneBundle(story: Story, name: string): Promise<string | string[]> {
        let storyBundle: IStoryBundle = this.readStoryBundleFromWindow();

        let sceneBundle = _.find(storyBundle.scene_bundles, (value: ISceneBundle) => value.name === name);

        if (!sceneBundle) {
            throw new Error(`Scene bundle not found ${name}`);
        }

        return Promise.all(_.map(sceneBundle.asset_bundles, bundle =>
            this.downloadOrLoadBundle(story, bundle)
                .then(blob => {
                    if (!blob) {
                        console.log(`Blob is null for ${bundle.name}, assuming it is already stored in the window`);
                        // Assume it is already stored in the window
                        return bundle.name;
                    }

                    console.log(`Found blob for ${bundle.name}, storing it in the window`);
                    return this.storeDataInWindow(blob, bundle.name);
                })));

    }

    private doGetRelease(story: Story): Promise<StoryRelease | null> {
        return story.getStoryReleases(true)
            .then((releases: StoryRelease[]) => _.filter(releases, release => release.isCompatibleWithSDK()))
            .then((releases: StoryRelease[]) => _.sortBy(releases, [release => release.sdk_major_version, release => release.sdk_minor_version, release => release.sdk_patch_version]))
            .then((releases: StoryRelease[]) => _.last(releases));
    }

    private downloadOrLoadBundle(story: Story, bundle: AssetBundle): Promise<Blob> {
        console.log(`downloadOrLoadBundle called for ${story.id}`);
        console.log(bundle);
        switch (bundle.progress_state) {
            case -1:
                throw new Error('Bad progress state');
            case 0:
                bundle.progress_state = 1;

                if (!bundle.release_id) {
                    bundle.progress_state = -1;
                    throw new Error('Release not provided');
                }

                return this.AssetBundleIDB.open()
                    .then(() => console.log('this.AssetBundleIDB.open() after'))
                    .then(() => this.getAssetUrlWithNameAndStore(story, bundle.release_id, bundle.name))
                    .then((assetUrl: string) => {
                        return this.AssetBundleIDB.requiresUpdate(story, story.current_release)
                            .then(requiresUpdate => {
                                if (requiresUpdate) {
                                    return this.downloadAndDecode(assetUrl)
                                        .then(blob => {
                                            console.log('AssetBundleProvider.downloadAndDecode completed');
                                            return this.AssetBundleIDB.storeAll(story, story.current_release, blob, bundle);
                                        });
                                } else {
                                    return this.AssetBundleIDB.readBlob(story, bundle)
                                        .then(blob => {
                                            if (blob) return blob;

                                            return this.downloadAndDecode(assetUrl)
                                                .then(blob => {
                                                    console.log('AssetBundleProvider.downloadAndDecode completed');
                                                    return this.AssetBundleIDB.storeAll(story, story.current_release, blob, bundle);
                                                });
                                        });
                                }
                            })
                            .catch(error => {
                                logger.error('AssetBundleProvider Failed', error, `... Retrying downloadAndDecode(${assetUrl})`);
                                return this.downloadAndDecode(assetUrl);
                            })
                    })
                    .catch(e => {
                        logger.error('Asset: Failed to open database', e);
                        return this.getAssetUrlWithName(bundle.release_id, bundle.name).then((assetUrl) => {
                            return this.downloadAndDecode(assetUrl.asset_url)
                        });
                    })
                    .then(blob => {
                        bundle.progress_state = 2;
                        return blob;
                    })
                    .catch(e => {
                        bundle.progress_state = -1;
                        throw e;
                    });
            case 1:
                console.log('Download already in progress');
                // return backOff(() => this.downloadOrLoadBundle(story,bundle), )
                return Promise.resolve(null);
            case 2:
                console.log('Download already complete');
                bundle.progress_state = 0;
                return this.downloadOrLoadBundle(story, bundle);
        }
    }

    private getAssetUrl(story: Story): Promise<string> {
        return this.AssetBundleIDB.open()
            .then(() => this.AssetBundleIDB.readAssetUrl(story.id))
            .then((url: string | null) => {
                if (url) return url;

                return AssetBundleApi.getAssetUrl(story.current_release.id)
                    .then(entity => this.AssetBundleIDB.storeAssetUrl(story, entity.asset_url));
            });
    }

    private getAssetUrlWithName(release_id: number, name: string): Promise<AssetUrlEntity> {
        return AssetBundleApi.getAssetUrlWithName(release_id, name);
    }

    private getAssetUrlWithNameAndStore(story: Story, release_id: number, name: string): Promise<string> {
        return this.getAssetUrlWithName(release_id, name)
            .then(entity => this.AssetBundleIDB.storeAssetUrl(story, entity.asset_url, name))
    }


    private downloadAndDecode(assetUrl: string): Promise<Blob> {
        return promiseRetry(
            (retry, attemptNumber) => {
                if (attemptNumber > 1) console.log(`Attempt number ${attemptNumber}`);
                return fetch(assetUrl)
                    .then(Api.checkStatus)
                    .catch((response: Response) => Api.doRetry(response, retry));
            })
            .then((response: Response) => response.blob());
    }

    private storeDataInWindow(blob: Blob, name: string): Promise<string> {
        const DATA = `${name}_asset_data`;
        const POINTER = `${name}_asset_pointer`;

        return blob.arrayBuffer()
            .then((binary: ArrayBuffer) => {
                console.log(`Setting ${DATA} with ${binary.byteLength} bytes`);
                window[DATA] = binary;
            })
            .then(() => name);
    }

    private storeStoryBundleInWindow(storyBundle: IStoryBundle): void {
        window['story_bundle_data'] = storyBundle;
    }

    private readStoryBundleFromWindow(): IStoryBundle {
        return window['story_bundle_data'];
    }
}
