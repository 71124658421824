import React, {Fragment} from 'react';
import logger from 'loglevel';
import {makeObservable, observable} from 'mobx';
import {observer} from 'mobx-react';
import {Button, Col, Row} from 'antd';

import {ComponentWithStore} from 'models/RootStore';
import {SubscriptionPlan} from 'models/subscriptionPlan/SubscriptionPlan';
import {Subscription} from 'models/subscription/Subscription';
import {BillingAddress} from 'models/billingAddress/BillingAddress';

import DefaultLayout from 'layouts/DefaultLayout';
import BillingAddresses from 'components/public/billing_addresses/_BillingAddresses';
import CreditCards from 'components/public/credit_cards/_CreditCards';
import SubscriptionForm from 'components/public/pages/subscriptions/SubscriptionForm';

import './SubscriptionNew.scss';
import SummaryPage from "components/public/pages/subscriptions/SummaryPage";
import {LoadingOutlined} from "@ant-design/icons";
import Chevron from "components/shared/icons/Chevron";
import Coupon from "models/coupon/Coupon";

interface ISubscriptionNewProps {
    nested: boolean;
    routeParams?: { subscriptionId: number }
}

interface ISubscriptionNewState {
    planSelected: boolean;
    hasBillingAddress: boolean;
    subscribed: boolean;
    submitting: boolean;
    selectedSubscriptionId?: number;
    selectedSubscription?: SubscriptionPlan;
    enteredSubscriptionKey?: string;
    coupon: Coupon;
    errorMessage: { name: string, message: string } | null;
}

const MemoSubscriptionForm = React.memo(SubscriptionForm)

class SubscriptionNew extends ComponentWithStore<ISubscriptionNewProps, ISubscriptionNewState> {
    @observable private billingAddress: BillingAddress;
    @observable private oldSubscription: Subscription;

    private coupon: Coupon;

    private hasOneBillingAddress = false;

    public constructor(props) {
        super(props);
        makeObservable(this);

        this.selectSubscription = this.selectSubscription.bind(this);
        this.handleSubscriptionError = this.handleSubscriptionError.bind(this);
        this.submitBillingAddress = this.submitBillingAddress.bind(this);
        this.onSaveCreditCard = this.onSaveCreditCard.bind(this);
        this.editBillingAddress = this.editBillingAddress.bind(this);
        this.editSubscriptionPlan = this.editSubscriptionPlan.bind(this);
        this.onCouponAdded = this.onCouponAdded.bind(this);


        const state: Partial<ISubscriptionNewState> = {};
        const subscriptionKey = this.readParams();
        if (subscriptionKey != null) {
            state.enteredSubscriptionKey = subscriptionKey
        }

        this.state = {
            planSelected: false,
            hasBillingAddress: false,
            subscribed: false,
            submitting: false,
            errorMessage: null,
            coupon: null,
            ...state
        };
    }

    public componentDidMount(): void {
        if (this.props.hasOwnProperty('routeParams') && this.props.routeParams?.subscriptionId) {
            let id: number = Number(this.props.routeParams.subscriptionId);
            this.oldSubscription = new Subscription(this.store.SubscriptionProvider).withId(id);
            // noinspection JSIgnoredPromiseFromCall
            this.oldSubscription.fetchData();
        }

        this.billingAddress = new BillingAddress(this.store.BillingAddressProvider).withCurrentUser();

        this.store.BillingAddressProvider
            .getActive(this.store.SessionProvider.userId())
            .then((address: BillingAddress) => {
                if (address) {
                    this.setState({hasBillingAddress: true})
                    this.billingAddress = address;
                    this.hasOneBillingAddress = true;
                }
            });
    }

    private readParams(): string {
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        if (params.has('beta_key')) {
            return params.get('beta_key');
        }
        return null;
    }

    private submitBillingAddress(): void {
        this.setState({submitting: true});

        this.billingAddress.save()
            // TODO: Shouldn't need to do this - need to look into what is happening
            .then(() => this.store.BillingAddressProvider.getActive(this.store.SessionProvider.userId()))
            .then((address: BillingAddress) => {
                if (address) {
                    this.billingAddress = address;
                }
            })
            .then(() => this.setState({hasBillingAddress: true, submitting: false}))
            .catch(error => {
                this.setState({submitting: false});
                console.error(error);
            });
    }

    private cancelOldSubscription(): Promise<any> {
        if (this.oldSubscription?.id) {
            return this.oldSubscription.cancel();
        } else {
            return Promise.resolve()
        }
    }

    private ifFreeCancelOldSubscription(selectedSubscription: SubscriptionPlan): Promise<any> {
        if (selectedSubscription?.isFree()) {
            return this.cancelOldSubscription();
        } else {
            return Promise.resolve()
        }
    }

    private selectSubscription(selectedSubscription: SubscriptionPlan, enteredSubscriptionKey?: string): void {
        this.ifFreeCancelOldSubscription(selectedSubscription)
            .then(() => {
                window.scrollTo(0, window.screenTop);
                this.setState({
                    selectedSubscriptionId: selectedSubscription.id,
                    selectedSubscription: selectedSubscription,
                    enteredSubscriptionKey: enteredSubscriptionKey,
                    planSelected: true
                });
            });
    }

    private handleSubscriptionError(errorInfo: any) {
        console.log('ErrorInfo: ', errorInfo);
    }

    private onCouponAdded(coupon: Coupon): Coupon {
        this.coupon = coupon
        return coupon;
    }

    private onSaveCreditCard(stripeSetupIntentId: string, creditCardId: number): Promise<any> {
        const {selectedSubscriptionId, enteredSubscriptionKey} = this.state;
        this.setState({
            errorMessage: null
        });
        return this.store.SubscriptionProvider.createSubscription({
            user_id: this.store.SessionProvider.userId(),
            subscription_plan_id: selectedSubscriptionId,
            subscription_plan_key: enteredSubscriptionKey,
            billing_address_id: this.billingAddress.id,
            stripe_setup_intent: stripeSetupIntentId,
            credit_card_id: creditCardId,
            coupon_id: this.coupon?.id
        })
            .then((result) => {
                if (result.subscription_id) {
                    this.cancelOldSubscription().then(() => this.setState({subscribed: true}));
                } else {
                    logger.error('Failed to add subscription:', result);
                    this.displayGenericErrorMessage(result.message);
                }
            })
            .catch(response => response.json()
                .then((result) => {
                    if (result.codes.includes('ERK|602') || result.codes.includes('ERK|604')) {
                        this.displayKeyInputErrorMessage('Invalid key provided');
                        this.setState({
                            planSelected: false,
                            enteredSubscriptionKey: undefined
                        });
                    } else if (result.codes.includes('ERK|605') || result.codes.includes('ERK|606')) {
                        this.displayGenericErrorMessage(result.message);
                    } else if (result.codes.includes('ERK|603')) {
                        this.cancelOldSubscription().then(() => this.setState({subscribed: true}));
                    } else {
                        logger.error('An error has occurred while submitting subscription:', result);
                        this.displayGenericErrorMessage(result.message);
                    }
                })
                .catch(error => {
                    logger.error('An error has occurred while submitting subscription and it failed to parse:', response, error);
                    this.displayGenericErrorMessage();
                })
            );
    }

    private editBillingAddress(_event: React.MouseEvent): void {
        this.setState({hasBillingAddress: false});
    }

    private editSubscriptionPlan(_event: React.MouseEvent): void {
        this.setState({planSelected: false});
    }

    private displayGenericErrorMessage(message: string = 'An error has occurred. Please try again.'): void {
        this.displayErrorMessage('generic_error_message', message);
    }

    private displayKeyInputErrorMessage(message: string = 'Invalid access key'): void {
        this.displayErrorMessage('subscription_plan_key', message);
    }

    private displayErrorMessage(name: string, message: string): void {
        this.setState({
            errorMessage: {
                name: name,
                message: message
            }
        });
    }

    private renderRegisterStep(number: number): React.ReactElement {
        const {nested} = this.props;

        if (!nested) return <React.Fragment/>

        return (
            <Row justify='center'>
                <p className='rk-register--step'>STEP {number} OF 4</p>
            </Row>
        );
    }

    private renderNewCreditCardFrom(): React.ReactElement {
        const {selectedSubscription, errorMessage} = this.state;

        return (
            <Fragment>
                <CreditCards.SetupForm billingAddress={this.billingAddress}
                                       subscriptionPlan={selectedSubscription}
                                       onSave={this.onSaveCreditCard}
                                       onCouponAdded={this.onCouponAdded}
                                       editBillingAddress={this.editBillingAddress}
                                       editSubscriptionPlan={this.editSubscriptionPlan}
                                       errorMessage={errorMessage}
                />
                <br/>
            </Fragment>
        );
    }

    private renderCreditCardPage(): React.ReactElement {
        const {selectedSubscription, errorMessage} = this.state;

        return (
            <React.Fragment>
                {this.renderRegisterStep(4)}

                {selectedSubscription && this.renderNewCreditCardFrom()}
            </React.Fragment>
        );
    }

    private renderBillingAddressPage(): React.ReactElement {
        const {submitting, hasBillingAddress} = this.state;
        return (
            <React.Fragment>
                {this.renderRegisterStep(3)}

                <BillingAddresses.Form billingAddress={this.billingAddress}/>

                <Row justify={"space-between"}>
                    <Col>
                        {this.renderPreviousStepButton()}
                    </Col>
                    <Col>
                        {this.billingAddress.hasRequiredFields ?
                            <Button className='rk-btn rk-btn-dark rounded submit-form'
                                    type='primary'
                                    htmlType='submit'
                                    onClick={this.submitBillingAddress}>
                                {(submitting || hasBillingAddress) ? <LoadingOutlined spin/> : 'Next'}
                            </Button>
                            :
                            <Button className='rk-btn rk-btn-dark rounded submit-form'
                                    type='primary'
                                    htmlType='submit'
                                    disabled
                                    onClick={this.submitBillingAddress}>
                                Next
                            </Button>
                        }
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    private renderSubscriptionSelectionPage(): React.ReactElement {
        const {errorMessage, selectedSubscriptionId} = this.state;

        return (
            <div>
                {this.renderRegisterStep(2)}

                <div className='rk-beta-key__form'>
                    <h2>Subscribe to a plan</h2>
                    <div>
                        <MemoSubscriptionForm key={1} embedded={true} onFinish={this.selectSubscription}
                                              onFinishFailed={this.handleSubscriptionError}
                                              selectedSubscriptionId={selectedSubscriptionId}/>
                        <div className='rk-beta-key__errors'>
                            <p>{errorMessage ? errorMessage.message : <React.Fragment>&nbsp;</React.Fragment>}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    private renderPages(): React.ReactElement {
        const {planSelected, subscribed, selectedSubscription} = this.state;

        if (planSelected) {
            const {hasBillingAddress} = this.state;
            if (selectedSubscription?.isFree()) {
                history.pushState({}, document.title, '/subscribed');
                return <SummaryPage embedded={true} subscriptionPlan={selectedSubscription}/>;
            }
            if (hasBillingAddress) {
                if (subscribed) {
                    history.pushState({}, document.title, '/subscribed');
                    return <SummaryPage embedded={true} subscriptionPlan={selectedSubscription}/>;
                }
                return this.renderCreditCardPage();
            }
            return this.renderBillingAddressPage();
        }
        return this.renderSubscriptionSelectionPage();
    }

    public hasPreviousStep(): boolean {
        const {planSelected, hasBillingAddress, subscribed, selectedSubscription} = this.state;
        return !subscribed && planSelected && (hasBillingAddress || !selectedSubscription?.isFree());
    }

    public moveToPreviousStep() {
        const {planSelected, hasBillingAddress} = this.state;
        if (planSelected) {
            if (hasBillingAddress && !this.hasOneBillingAddress) {
                this.setState({
                    hasBillingAddress: false
                });
            } else {
                this.setState({
                    planSelected: false
                });
            }
        }
    }

    private renderPreviousStepButton() {
        if (!this.hasPreviousStep()) return null;
        const {submitting} = this.state;

        if (submitting) {
            return (
                <Button className='rk-btn rk-btn-dark rounded submit-form' disabled>
                    <Chevron direction={'left'}/>
                </Button>
            );
        } else {
            return (
                <Button className='rk-btn rk-btn-dark rounded submit-form'
                        type='primary' onClick={() => this.moveToPreviousStep()}>
                    <Chevron direction={'left'}/>
                </Button>
            );
        }
    }

    public render(): React.ReactElement {
        const {nested} = this.props;

        if (nested) return this.renderPages();

        return (
            <DefaultLayout>
                <div className='rk-subscription-content'>
                    <div className='rk-form-container'>
                        {this.renderPages()}
                    </div>
                </div>
            </DefaultLayout>
        );
    }
}

export default observer(SubscriptionNew);
