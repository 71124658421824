export class UnityEvents {
    public static readonly DOWNLOAD_SCENE_BUNDLE_EVENT: string = 'RKDownloadSceneBundle';
    public static readonly STARTUP_COMPLETE_EVENT: string = 'RKStartupComplete';
    public static readonly START_STORY_SESSION_EVENT: string = 'RKStartStorySession';
    public static readonly UPDATE_CURRENT_ASSET_BUNDLE_EVENT: string = 'RKUpdateCurrentAssetBundle';
    public static readonly UPDATE_CURRENT_SCENE_EVENT: string = 'RKUpdateCurrentScene';
    public static readonly FINISH_STORY_SESSION_EVENT: string = 'RKFinishStorySession';
    public static readonly DISPLAY_ERROR_EVENT: string = 'RKDisplayError';
    public static readonly STORY_SET_EVENT: string = 'RKStorySet';
    public static readonly PROGRESS_STATE_CHANGE_EVENT: string = 'RKProgressStateChange';
    public static readonly EXIT_EVENT: string = 'RKExit';
}