import {RootStore} from 'models/RootStore';
import {IExposedPermanentStore, PermanentStore} from 'models/PermanentStore';
import {ISubscriptionPlanApiData} from 'models/subscriptionPlan/ISubscriptionPlanApiData';

export class SubscriptionPlanPermanentStore extends PermanentStore implements IExposedPermanentStore<ISubscriptionPlanApiData> {
  constructor(rootStore: RootStore) {
    super(rootStore, 'SubscriptionPlan', true);
  }

  public getKey(id: number): string {
    return `api_subscription_plan_${id}`;
  }

  public store(data: ISubscriptionPlanApiData): Promise<void> {
    return this.doStore<ISubscriptionPlanApiData>(this.getKey(data.id), data);
  }

  public read(id: number): Promise<ISubscriptionPlanApiData | null> {
    return this.doRead<ISubscriptionPlanApiData>(this.getKey(id));
  }
}
