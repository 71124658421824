import {RootStore} from 'models/RootStore';
import {IExposedPermanentStore, PermanentStore} from 'models/PermanentStore';
import {ICollectionApiResponseData} from 'models/collection/ICollectionApiData';

export class CollectionPermanentStore extends PermanentStore implements IExposedPermanentStore<ICollectionApiResponseData> {
    constructor(rootStore: RootStore) {
        super(rootStore, 'Collection', true);
    }

    public getKey(id: number): string {
        return `api_collection_${id}`;
    }

    public store(data: ICollectionApiResponseData, id: number): Promise<void> {
        return this.doStore<ICollectionApiResponseData>(this.getKey(id), data);
    }

    public read(id: number): Promise<ICollectionApiResponseData | null> {
        return this.doRead<ICollectionApiResponseData>(this.getKey(id));
    }
}
