import _ from 'lodash';

import {AbstractStore} from 'models/AbstractStore';
import {RootStore} from 'models/RootStore';
import {Story} from 'models/story/Story';
import {IStoryApiData} from 'models/story/IStoryApiData';
import {StoryApi} from 'models/story/StoryApi';
import {ApiResponseData} from 'models/ApiResponseData';
import {IRole, IStoryUpdateApiData} from 'models/story/IStoryUpdateApiData';

export class StoryProvider extends AbstractStore {
    public constructor(rootStore: RootStore) {
        super(rootStore, 'Story');

        this.apiDataArrayToModelArray = this.apiDataArrayToModelArray.bind(this);
        this.apiDataToModel = this.apiDataToModel.bind(this);
    }

    public getAll(): Promise<Story[]> {
        return this.getAllFromApiAndUpdateStore();
    }

    public getFavourites(): Promise<Story[]> {
        return this.getFavouritesFromApiAndUpdateStore();
    }

    public getRelated(id: number): Promise<Story[]> {
        return this.getRelatedFromApiAndUpdateStore(id);
    }

    public get(id: number, story: Story = new Story(this), force: boolean = false): Promise<Story> {
        if (id == null) return Promise.reject('Story Id is null');
        if (force) return this.getFromApiAndUpdateStore(id, story);

        return this.StoryMemoryStore.read(id)
            .then((apiData: IStoryApiData | null) => {
                return apiData
                    ? this.apiDataToModel(apiData)
                    : this.getFromApiAndUpdateStore(id, story);
            });
    }

    public create(story: Story): Promise<ApiResponseData> {
        return StoryApi.create(story);
    }

    public update(id: number, data: IStoryUpdateApiData): Promise<IStoryApiData> {
        return StoryApi.update(id, data);
    }

    private getAllFromApiAndUpdateStore(): Promise<Story[]> {
        return StoryApi.getAll()
            .then((stories: IStoryApiData[]) => this.StoryMemoryStore.storeAll(stories)
                .then(() => this.apiDataArrayToModelArray(stories)));
    }

    private getFavouritesFromApiAndUpdateStore(): Promise<Story[]> {
        return StoryApi.getFavourites()
            .then((stories: IStoryApiData[]) => this.StoryMemoryStore.storeAll(stories)
                .then(() => this.apiDataArrayToModelArray(stories)));
    }

    private getRelatedFromApiAndUpdateStore(id: number): Promise<Story[]> {
        return this.StoryMemoryStore.read(id)
            .then((apiData: IStoryApiData | null) => apiData || StoryApi.get(id))
            .then((apiData: IStoryApiData) => {
                if (apiData.related) return Promise.all(_.map(apiData.related, relatedId => this.get(relatedId)))

                return StoryApi.getRelated(id)
                    .then((stories: IStoryApiData[]) =>
                        this.StoryMemoryStore.storeAll(stories)
                            .then(() => apiData.related = _.map(stories, story => story.id))
                            .then(() => this.StoryMemoryStore.store(apiData))
                            .then(() => this.apiDataArrayToModelArray(stories)));
            });
    }

    private getFromApiAndUpdateStore(id: number, story: Story = new Story(this)): Promise<Story> {
        return StoryApi.get(id)
            .then((apiData: IStoryApiData) => this.StoryMemoryStore.store(apiData)
                .then(() => this.apiDataToModel(apiData, story)));
    }

    public getCredits(id: number): Promise<IRole[]> {
        return StoryApi.getCredits(id);
    }

    private apiDataArrayToModelArray(stories: IStoryApiData[]): Story[] {
        return stories.map((apiData: IStoryApiData) => this.apiDataToModel(apiData));
    }

    private apiDataToModel(apiData: IStoryApiData, story: Story = new Story(this)): Story {
        return story.withData(apiData);
    }
}
